.pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  margin-bottom: 0;
  @include breakpoint(medium up) {
    margin-bottom: rem-calc(20);
  }
  li {
    margin-bottom: 2px;
    &:before {
      margin: 0;
      background: none;
    }
  }
  .break {
    flex-basis: 100%;
    height: 0;
  }
  .sub {
    a {
      color: #bbb;
    }
  }
  .inactive {
    color:#808080;
    text-transform: uppercase;
    margin-right: 1rem;
    position: absolute;
    left: 0;
    display: none;
    @include breakpoint(medium up) {
      display:block;
    }
    &:before {
      height:0;
      width:0;
    }
  }
}

