@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}
@mixin border-top {
  position: relative;
  &:before {
    content: "";
    left: (map-get($grid-padding-gutters, medium)/2);
    right: (map-get($grid-padding-gutters, medium)/2);
    top: (map-get($grid-padding-gutters, medium)/2);
    height: 1px;
    position: absolute;
    border-top: dotted 1px #979797;
  }
}

@mixin border-bottom {
  position: relative;
  &:after {
    content: "";
    left: (map-get($grid-padding-gutters, medium)/2);
    right: (map-get($grid-padding-gutters, medium)/2);
    bottom: (map-get($grid-padding-gutters, medium)/2);
    height: 1px;
    position: absolute;
    border-top: dotted 1px #979797;
  }
}

 @mixin parallel3Sitzen {
  display: inline-block;
  width: 48.5%;

}
@mixin parallel4Sitzen {
  display: inline-block;
  width: 48.5%;

}
@mixin abs-marginButtom {
  margin-bottom: 20px;
}
@mixin parallelSitzen {
  display: inline-block;
  width: 51%;
}

@mixin marginClass {
  width: 100%;
  margin-bottom: 10px;
}

@mixin frnbottomrightbar_fix {
  margin-right: 2px; margin-top: 4px; margin-bottom: 4px;
}



// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone-min {
    @media only screen and (max-width: 320px) {
      @content
    };
  }
  @if $breakpoint == phone {
    @media only screen and (max-width: 40em) {
      @content
    }; //640px
  }
  @if $breakpoint == tab-ipod {
    @media only screen and (max-width: 56.25em) {
      @content
    }; //900px
  }
  /* @if $breakpoint == tab-land {
       @media only screen and (max-width: 75em) { @content };    //1200px
   }
   @if $breakpoint == big-desktop {
       @media only screen and (min-width: 112.5em) { @content };    //1800
   }  */
  @if $breakpoint == tab-port {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
      @content
    };
  }


}

