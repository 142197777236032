
.ConsentLinks {
    font-size: .8rem;
    padding-top: 10px;
    a {
      text-decoration: underline;
    }
}

.fnConsEmbed{
  & p {
    margin-bottom: 4px;
  }
  &__placeholder {
    padding: 10px;
    border: solid 1px lightgrey;
    font-size: .8rem;
    span {
      font-weight:bold;
      font-size: 1rem;
    }
  }
  &-vendor {
    display:none;
  }
  &__logo {
    display:none;
  }
}
.fnConsEmbed p.fnCons__headline  {
  font-size: 1rem;
  margin-bottom: 4px;
}

.disclaimer a {
  text-decoration: underline;
}

.fnConsEmbedSwitchToggleContainer .switch {
  margin-bottom: 5px;
  max-width: 320px;  /* 400px medium */
  background: #84bc34;
  padding: 0 10px;
  .slider {
    background: none;
    font-size: .8rem;
  }
}

.frn-article__left .disclaimer,
.frn-article__left .fnConsEmbed-Text {
  font-size: .9rem;
}
