/* space reservation */
.index #login {
  min-height: 53px;
  @include breakpoint(medium) {
    min-height: 410px;
  }
}
.login-standard {
  min-height: 24px;
}
#frnEmailPre {
  min-height: 73px;
}
/* Teaser */
picture.lg {
  min-height: 238px;
  @include breakpoint(medium) {
    min-height: 278px;
  }
  @include breakpoint(large) {
    min-height: 413px;
  }
}
picture.sm {
  min-height: 115px;
  @include breakpoint(medium) {
    min-height: 236px;
  }
  @include breakpoint(large) {
    min-height: 200px;
  }
}
picture.md {

}
picture.sm2lg {
  min-height: 115px;
  @include breakpoint(medium) {
    min-height: 200px;
  }
  @include breakpoint(large) {
    min-height: 413px;
  }
}
picture.lg2sm {
  min-height: 237px;
  @include breakpoint(medium) {
    min-height: 236px;
  }
  @include breakpoint(large) {
    min-height: 200px;
  }
}
picture.sm-vert {
  min-height: 115px;
  @include breakpoint(medium) {
    min-height: 236px;
  }
  @include breakpoint(large) {
    min-height: 200px;
  }
}
picture.flyout {
  display: none;
  @include breakpoint(large up) {
    display: block;
    min-height: 205px;
  }
}