.frnKopfWetter_head {
  margin-left: auto;
  margin-right: 15px;
  margin-top: 1px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(90, 99, 116);
  cursor: pointer;
  font-family: 'Open Sans Condensed','Arial Narrow',Arial,Helvetica,sans-serif;
  .frnWetterContent {
    right: 8px;
    position: absolute;
    top: 0px;
    text-align: right;
    min-width: 120px;
    height: 100%;
  }
}
#frn_wetterBoxWsName {
  font-size: 15px; display: block; text-align: center;
  @include breakpoint(medium up) {
    display: inline-block;
  }
}
.frn_24h_wetterZahl  {
  margin-left: 25%;
  @include breakpoint(medium up) {
    margin-left: 0;
  }
}
.frn_weather_icon_header_outer  {
  display: inline-block;
  width: 29px;
  position: relative;
  top: 6px;
 // top: 0px;
}
.cls-1, .cls-2 {
  stroke-width: 3px !important;
}