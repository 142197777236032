.frnmaillogin   {
  padding: 0.7rem 1rem 0rem 1rem; background: #84bc34; color: white;
  h2  {
    font-size: 22px;
    margin-bottom: 15px;
    padding: 0px 0px 13px 0px;
    text-align: center;
    @include breakpoint(medium up) {
      padding: 0;
      text-align: left;
    }
  }
  button.primary {
    font-weight: bold;
  }
}
.frn_cloudMail {
  background: rgb(231, 235, 242); padding: 1rem 0.7rem 1rem 1rem;
  h2  {
    font-size: 22px;
  }
}
.frnRegisterieren {
  padding: 0rem 1rem;
  @include breakpoint(medium up) {
    background: rgb(231, 235, 242);
  }
  button.primary {
    font-weight: bold;
    @include breakpoint(medium up) {
      background: $frncolor-secondary;
    }
  }
}
#login ul li:before, .login ul li:before {
  display: none;
}

.login-standard {
  position: absolute;right: 30px;margin-top: 18px;
  z-index: 10;
}


.loginbox.mobile{
  .form, .form-footer, .login_area, .frn_loginBoxTrennerPasskey, .login-button-container{
    display: none !important;
  }
  .login-logo {
    margin-bottom: 0 !important;
    text-align: center;
  }
  .nameLine {
    text-align: center;
  }
}

.loginbox.mobile.unfold{
  .form, .form-footer{
    display: block !important;
  }
  .login_area, .frn_loginBoxTrennerPasskey, .login-button-container{
    display: flex !important;
  }
  .login-logo {
    margin-bottom: 12px !important;
  }
}

#frnEmailPre {
  margin-top: -29px;
}