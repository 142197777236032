@import "abstracts/functions";
@import "abstracts/mixins";
@import "abstracts/variables";
@import "global/linkhoverglobal";
@import "layouts/main";
@import "layouts/header";
@import "layouts/footer";

@import "layouts/article";
@import "layouts/pictureshow";
@import "global/typography";
@import "global/ads";
@import "global/pureContent";
@import "components/frnLoginMail";
@import "components/frnWetter";
@import "components/frnSportTicker";
@import "components/frnArchivePage";
@import "layouts/spacereservation";
//@import "layouts/titanium";
@import "layouts/digitaristaAmazon";
//@include foundation-xy-grid-classes;

// normalize does a max-width only which doesn't overwrite the width attribute
picture img {
  width:100%;
}

// testonly
.callout {
  margin:0;
  height:100%;
}

:focus{
  outline:none;
}
.clearfix {
  @include clearfix;
}
.clearBoth {
  @include abs-marginButtom;
}

.divImage1 {
  @include clearfix;
}
.parallel3 {

  @include respond(tab-ipod) {
    @include parallelSitzen;
    margin-right: 0.5rem !important;
  }
  @include respond(phone) {
    @include parallelSitzen;
    margin-right: 1rem !important;
  }
}
.parallel4 {

  @include respond(tab-ipod) {
    @include parallelSitzen;

  }
  @include respond(phone) {
    @include parallelSitzen;

  }
}
.rectangle {
  max-width: 301px;
  height: 251px;
  background-color: lightgrey;
  margin: 0 auto;
  padding-top: 100px;
  text-align: center;
}
.rectangleKlein {
  max-width: 302px;
  height: 78px;
  background-color: pink;
  margin: 0 auto;
  padding-top: 30px;
  text-align: center;
}

.MDAX {
  height: 301px;
  max-width: 323px;
  background-color: grey;
  margin: 0 auto;
  padding-top: 100px;
  text-align: center;
}
.bilderShow {
  height: 314px;
  max-width: 320px;
  background-color: gainsboro;
  margin: 0 auto;
  padding-top: 100px;
  text-align: center;
}
@include respond(tab-port) {
  .boxcont li {
    font-size: 0.775rem;
  }
}

.marginClass {
  @include marginClass;

}

.imageTextMargin {
  @include margin(0, 0, 0.5, null); // top right buttom margin
}


@include respond(tab-port) {
  .grid-container {
    font-size: 0.775rem;
  }
}

/* Overwrite Slick theme */
@if $slick-font-family == "slick" {
  @font-face {
    font-family: "slick";
    src: slick-font-url("slick.woff") format("woff"), slick-font-url("slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}
/* Slider */
.slick-list {
  .slick-loading & {
    background: #fff center center no-repeat;
  }
}

/* EOF Marker */
@import "src/assets/scss/global/check-mk";