.digitarista_Amazon {

  .digitarista-title {
    margin: 1.5rem 0 0rem;
    a {
      color: #e7813e;
      text-decoration: none;
    }
  }
  .digitarista-name {
    margin: 0rem 0 0rem;
  }
  .digitarista-content {
    font-weight: 400;
    font-size: 14px;
  }
  img.digitarista-image  {
    width: 250px;
    height: 250px;
    margin-left: 26px;
  }
  .digitarista-icon-star {
    height: 18px;
    width: 80px;
  }
  .digitarista-icon {
    display: inline-block;
    vertical-align: top;
  }

  .digitarista-icon.digitarista-icon-star {
     background-image: url("../img/amazonTest.png");
     background-size: 512px 512px;
     background-repeat: no-repeat;
   }



  /*.digitarista-icon.digitarista-star-4-5 {
    background-position: -2px -42px;
  } */
  .digitarista-icon.digitarista-star-5{
    background-position:-2px -2px
  }
  .digitarista-icon.digitarista-star-4{
    background-position:-2px -22px
  }
  .digitarista-icon.digitarista-star-3{
    background-position:-2px -62px
  }
  .digitarista-icon.digitarista-star-2{
    background-position:-2px -102px
  }
  .digitarista-icon.digitarista-star-1{
    background-position:-2px -142px
  }
  .digitarista-icon.digitarista-star-0{
    background-position:-2px -182px
  }
  .digitarista-icon.digitarista-star-4-5{
    background-position:-2px -42px
  }
  .digitarista-icon.digitarista-star-3-5{
    background-position:-2px -82px
  }
  .digitarista-icon.digitarista-star-2-5{
    background-position:-2px -122px
  }
  .digitarista-icon.digitarista-star-1-5{
    background-position:-2px -162px
  }
  .digitarista-icon.digitarista-star-0-5{
    background-position:-2px -202px
  }

  #digiristaCustomerReviewText {
    position: relative;
    /* vertical-align: text-top; */
    top: -4px;
    color: #007185;
  }

  .digitarista-price {
font-weight: bold;
    .digitarista-price-whole {
      font-size: 24px;
    }
    .digitarista-price-symbol {
      font-size: 24px;
    }
  }

.digitarista-summary {
  margin-top: 10px;
  padding-top: 16px;

  border-top: 1px solid rgba(0, 0, 0, 0.25);
}


.digitarista-badge {
  clip-path: polygon(0 0, 100% 0, 92% 100%, 0 100%);
  background: black;
  color: white;
  font-weight: 600;
  display: inline-block;
  /* width: fit-content; */
  height: fit-content;
  /* padding: 0px; */
 // width: calc(100% + 20px);
  padding: 3px 18px 5px 12px;

}

}