#frnBanner {
  min-height: 120px;
  padding: 18px 0 18px 0px;
  z-index: 101;
  //margin-top: 62px;
  background-color: #21314d;
  @include breakpoint(large up) {
    //margin-top: 99px;
    margin-top: 0;
    padding: 5px 0 15px 0px;
    z-index: 0;
    //min-height: 270px;
  }
  img {
    height: 75px;
    @include breakpoint(medium up) {
      height: auto;
    }
  }
  &.minimize{
    min-height: 0;
  }
}
#frnBannerAd {
  width: 92%;
  position: relative;
  //min-height: 90px;
  margin-left: 14px;
  @include breakpoint(medium only) {
    width: 100%;
    scale: 0.75;
    margin: -40px 0;
  }
  @include breakpoint(large up) {
    width: 100%;
    margin-left: 0;
  }
  @include breakpoint(large only) {
    //zoom: 1.25;
    //scale: 0.75;
    //margin: -34px 0;
  }
}
#frnAdSky {
  position: absolute;
 // top:15px;
  top: 0;
  width: 0;
  right: 0;
  z-index: 100;
}
#frnAdSkyPos {
  position: absolute;
  padding-left: 10px;
}
@include respond(phone-min) {
  .ad-mobile { margin-left: -10px; }
}
#ad-rectangle, #ad-coop_button_1, #ad-rectangle2, #ad-rectangle3, #ad-rectangle4 {
  .sdgSlotHull {
    &:before{
      content: "Anzeige";
      top: -15px;
      left: 0;
      //right: calc(50% + 150px);
      color:#707070;
      z-index: 10;
      position: absolute;
      //text-transform: uppercase;
      //background: rgba(0,0,0,0.07);
      //background: rgba(0,0,0,0.6);
      //padding: 1px 4px 1px;
      font-size: rem-calc(12);
      font-weight: 700;
      line-height: 1;
      //transform:rotate(-90deg);
      //transform-origin: right top;
      //width: 100%;
    }
  }
}
#ad-rectangle.rectangle-centered {
  max-width: 300px;
  margin: 0 auto 20px;
}
#sdgSlotHull-topmobile, #sdgSlotHull-topmobile2, #sdgSlotHull-topmobile3, #sdgSlotHull-topmobile4, #sdgSlotHull-topmobile5, #sdgSlotHull-topmobile6 {
   &:before{
      content: "Anzeige";
      top: 18px;
      left: -31.7px;
      color:#fff;
      z-index: 10;
      position: absolute;
      text-transform: uppercase;
      background: rgba(0,0,0,0.6);
      padding: 1px 4px 1px;
      font-size: rem-calc(10);
      line-height: 1.2;
      transform:rotate(-90deg);
    }
}
.sdgAnzeigenkennung {
  &.sdgAnz-topmobile2, &.sdgAnz-topmobile3, &.sdgAnz-topmobile4, &.sdgAnz-topmobile5, &.sdgAnz-topmobile6 {
    &:before {
      display:none;
    }
  }
}
#ad-frn_recommendation1, #ad-frn_recommendation2, #ad-frn_recommendation3, #ad-frn_recommendation4, #ad-frn_recommendation5 {
  [id^=anzeige_] {
    display: none;
  }
}
#adBox11 {
  margin-bottom: 1rem;
}
#ad-rectangle, #ad-rectangle2, #ad-rectangle3, #ad-rectangle4 {
  min-height: 250px;
}

#ad-listbreaker1, #ad-listbreaker2, #ad-listbreaker3, #ad-pubperform {
  margin-top: -20px;
  margin-bottom: 25px;
  > div {
    &:before {
      content: "Anzeige";
      background: none;
      color: $frncolor-startgrey;
      width: auto;
      top: 0;
      left:47.5%;
      margin-top: -20px;
      transform:none;
      font-size: rem-calc(12);
      position: relative;
    }

  }
}
#ad-pubperform {
  margin-bottom: 35px;
}
#ad-topmobile2 {
  margin-bottom: 25px;
}
#ad-topmobile3{
  margin-bottom: 50px;
}
.googlead, #adBoxGoogle {
  position: relative;
  &:before {
    content: "Anzeige";
    top: 18px !important;
    left: -16.7px !important;
    color: #fff;
    z-index: 10;
    position: absolute;
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.6);
    padding: 1px 4px 1px !important;
    font-size: rem-calc(10) !important;
    line-height: 1.2;
    transform: rotate(-90deg);
  }
}
.imagegallery {
  div[id^="adBoxmobb"] {
    margin-bottom: 20px;
  }
}

.frn_adbox{
  display: flex;
  justify-content: center;
  align-items: center;
}
.cpauthenticated {
  .frn_adbox, .frn-container--shopping, .frn-container--shopping1, #adBox-mpu {
    display: none;
  }
}
.article .ConsentLinks {
  margin-bottom: 20px;
  margin-top: -20px;
}
.article .fnConsEmbed__placeholder {
  margin-bottom: 20px;
}

.article-rectangle3 {
  width:300px;
  margin:-2rem auto -1rem;
}

.frn-header .ad-mobile {
  min-height: 73px;
  padding: 5px 0 18px 0;
  z-index: 101;
  background-color: #21314d;
}

.st-wallpaper {
  #frnBanner, .frn-header {
    background:transparent;
  }
}
.st-fireplace {
  #frnMain, .frn-header, #frn-footer {
    margin-left:auto;
    @media print, screen and (min-width: (map-get($breakpoints, xlarge) + ($grid-container-center-shifting*2) + 20)){
      left: auto;
    }
  }
}
.st-direction {
  @include breakpoint(large up) {
    #frnMain {
      left: 0;
      margin-left:  auto;
    }
    .frn-header {
      left: 0;
      margin-left:  auto;
    }
  }
}
// opinary
#opinary-root {
  margin-bottom: 1rem !important;
}
glomex-player[act="initially-hidden"] {
  margin-bottom: 1rem;
}
// maxiad
.logout {
  #ad-banner {
    @include breakpoint(medium only) {
      scale: 0.75;
      margin: -50px;
    }
    @include breakpoint(large only) {
      zoom: 1.25;
      scale: 0.75;
      margin: -50px;
    }
  }
}