// COLORS
$frncolor-primary: #21314D;
$frncolor-secondary: #84BC34;
$frncolor-news: #1175C3;
$frncolor-unterhaltung: #E33AB9;
$frncolor-finanzen: #5BC3CB;
$frncolor-sport: #1A9A45;
$frncolor-lifestyle: #D4121A;
$frncolor-auto: #A69B89;
$frncolor-spielen: #F3B105;
$frncolor-digital: #F98110;
$frncolor-mega: rgb(33, 49, 77);
$frncolor-webcard: rgb(33, 49, 77);
$frncolor-mail: #84bc34;
$frncolor-domain: #fec600;
$frncolor-partnersuche: #f0909e;

$frncolor-newsHover: #1175C3;
$frncolor-unterhaltungHover: #F254CB;
$frncolor-finanzenHover: #70D3D6;
$frncolor-sportHover: #2CB158;
$frncolor-lifestyleHover: #E94B51;
$frncolor-autoHover: #BBB1A1;
$frncolor-spielenHover: #F3B105;
$frncolor-digitalHover: #F89940;
//$frncolor-megaHover: #008eed;
//$frncolor-webcardHover: #008eed;
$frncolor-mailHover: #76aa2c;
$frncolor-domainHover: #f1b026;
$frncolor-partnersucheHover: #f0909e;


$frncolor-startgreen: #84BC34;
$frncolor-hovergreen: #76aa2c;
$frncolor-startgrey: #828A9A;
$frncolor-hovergrey: #5a6374;
$frncolor-startdarkgrey: #21314d;
$frncolor-hoverdarkgrey: #162133;
$frncolor-startblue: #008eed;
$frncolor-hoverblue: #0093d4;
$frncolor-startyellow: #fec600;
$frncolor-hoveryellow: #f1b026;
$frncolor-Grau3: #cccccc;


// 4. Base Typography
// ------------------
$list-item-margin: 1.25rem;


