

#frnBreadcrumb {
  margin-left: 0.625rem;
  width: 100%;
  & li {
    display: none;
    &:nth-Child(2){
      display: inline;
      @include breakpoint(small only) {
        font-size: rem-calc(19);
        text-transform: uppercase;
        a {
          margin-right: -20px;
          background: #fff;
          z-index: 1;
          position: relative;
        }
      }
    }
    @include breakpoint(medium) {
      display: inline;
    }
    &:before {
      content: none;
    }
  }
}

.frn-article {
/*  .freenet_ArticleBreadcrumb {
    font-size: 14px;
  } */
  &__left {
    a{
      color: #84bc34;
    }
    h2, h3, h4, h5, h6 {
      font-weight: 600;
      margin: 3rem 0 1rem;
    }
    h3, h4, h5, h6 {
      font-size: 1.125rem;
    }
    h2 {
      font-size: 1.500rem;
    }
    hyphens: auto;
    @include breakpoint(medium up) {
      border-right: dotted 1px #979797;
      padding-right: 18px;
    }
    @include breakpoint(large) {
      padding-right: 28px;
    }
    .article_imagetitlesection {
      position: relative;
      border-bottom: dotted 1px #979797;
      padding-bottom: 10px;
    }
    .article_publicationdate {
      color: #7F90AD;
    }
    .frn_backToHome_btn {
      padding: 8px 18px 8px 18px;
      color: rgb(255, 255, 255);
      font-size: 16px;
      font-weight: 800;
      text-align: center;
      margin-left: 0.625rem;
      background: $frncolor-startdarkgrey;
    }
    .frn_backToHome_btn:hover {
      background: $frncolor-hoverdarkgrey;
    }
    .frn_articleList{
      background: rgb(231, 235, 242);
      padding: 18px 9px 0px 5px;
    }
  }
  &__right {
    > .cell, .frn_adbox, .frn_sportLiveTab {
      margin-bottom:30px;
    }
  }
}
.freenetarticleDate_socialmedia {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .article_socialMedia a {
    margin-right: 12px;
  }
  .article_socialMedia a:last-child {
    margin-right: 0;
  }
}
.orbit-previous {
  &:before {
    display: block;
    content: "";
    background-image: url(../img/icon-next.png);
    margin: 0 0 0 auto;
    transform: rotate(180deg);
    color: #333;
    width: 32px;
    height: 49px;
    left: 0;
    position: absolute;
    top: 0;
  }
  &:hover {
    background-color: #fff;
  }
}

.orbit-next {
  height: 48px;
  &:before {
    display: block;
    content: "";
    background-image: url(../img/icon-next.png);
    margin: 0 0 0 auto;
    color: #333;
    width: 32px;
    height: 49px;
    left: 0;
    position: absolute;
    top: 0;
  }
  &:hover {
    background-color: #fff;
  }
}

h1 {
  font-family: "Open Sans Extrabold";
  font-weight: 700;
  @include breakpoint(medium up) {
    font-weight: 600;
  }

}

 .article {
  &-image {
    margin-bottom:10px;
  }
  &_subtitle, &_subtitle p {
    margin-bottom: 1rem ;
    //font-size: rem-calc(14);
    font-size: rem-calc(18);
    font-weight: 700;
    @include breakpoint(large up) {
      font-size: rem-calc(18);
    }
  }
  .slick-prev, .slick-next {
    display: block;
    height: 63px;
    transform: none;
  }
  .slick-prev:before, .slick-next:before {
    display: inline-block;
  }
}


 article.frn-article__left{
  p, li {
    font-size: rem-calc(18);
    @include breakpoint(large up) {
      font-size: rem-calc(18);
    }
  }
}

/* copyright article & article image */
#frnArtCopyrightArticle {
  color: #808080;font-size: 0.8em;
}
#frnArtCopyrightArticleImage {
  color: #808080;font-size: 0.8em;
  width: 100%;text-align: right;
}

.article_content {
  position: relative;
  >p, >section>p, >ul, >section>ul, >div, >section>div {
    &:last-child:after {
      content: " ";
      background: url(../img/freenet-Logo.svg) no-repeat left center;
      background-size: 91px;
      background-position-x: 5px;
      padding-left: 30px;
    }
  }
  >ul, >section>ul{
    &:last-child:after{
      margin-left: -28px;
    }
  }
}

/* new articles css added */
#frnNewArticles {
  width: 100.3%;
  background: #ececec;
  transition: height 0.9s cubic-bezier(0,0,0.2,1) 0.1s;
  //height: 0;
  overflow: hidden;
  float: none;
  //display: none;
  display: none;
  @include breakpoint(large up) {
    display: none;
  }
}
#frnNewArticles a {
  color: #690;
  text-decoration: none;
}
#frnNewArticles .frn_contHead {
  border-width: 3px;
  border-color: #fff;
  height: 50px;
  margin-left: 12px;
  margin-right: 12px;
  border-bottom: 3px solid #fff;
}
#frnNewArticles .frn_contHead strong {
  background-color: transparent;
  color: #333;
  padding: 15px 7px;
  font-size: 14px;
  @include breakpoint(large up) {
    padding: 16px 7px;
    font-size: 16px;
  }
}
.frn_contHead strong {
  float: left;
  text-transform: uppercase;
  height: 100%;
  font-style: normal;
  padding: 5px 0;
  font-weight: 700;
  color: #333;
  margin: 0;
  line-height: normal;
  font-weight: 700;
}
#frnNewArticles div.frn_onlyList ul li {
  // font-size: 1.5em;
  width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
 margin-bottom: 5px;
  @include breakpoint(medium up) {
    width: 48%;
  }
}
#frnNewArticles #newArticlesUpdate {
  margin-left: 0;
  margin-bottom: 26px;
}
#frnNewArticles #newArticlesUpdate li a span {
  text-transform: uppercase;
}
#frnNewArticles #newArticlesUpdate li a strong {
  font-weight: normal;
}
#newArticlesUpdate .article_2, #newArticlesUpdate .article_3, #newArticlesUpdate .article_4, #newArticlesUpdate .article_5  {
 display: none;
  @include breakpoint(medium up) {
    display: block;
  }
}
div.frn_onlyList ul li {
  margin: 0 0 10px 0;
  height: auto;
  padding: 0 0 2px 18px;
  @include breakpoint(large up) {
    padding: 0 0 2px 10px;
  }
}
div.frn_onlyList ul li, .frn_tTeaser {
  background-image: none;
  position: relative;
}
div.frn_onlyList ul, div.frn_onlyList ul li {
  // line-height: 1.79;
  // font-size: 100%;
}


#frnNewArticles li:nth-child(4n), #frnNewArticles li:nth-child(5n), #frnNewArticles li:nth-child(6n), #frnNewArticles li:nth-child(7n), #frnNewArticles li:nth-child(8n), #frnNewArticles li:nth-child(9n) {
  margin-left: 50%;
}

#frnNewArticles li:nth-child(8n-4) {
  margin-top: -90px;
}
#frnNewArticles ul li:before {
  display: none;
}
#newarticlecircledot {
  height: 18px;
  width: 18px;
  background: #76aa2c;
  border-radius: 50%;
  display: none;
  position: absolute;
  /* right: 0; */
  left: 5%;
  top: -22%;
  cursor: pointer;
  z-index: 2;
  @include breakpoint(large up) {
    display: inline-block;
  }
}
#newarticlecircledot span {
  position: absolute;
  left: 20%;
  top: -22%;
  color: white;
}

/* session layers articles css*/
/* inactive layer css added */
.frnHomePreviewInfoLayer_bg {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1234567850;
  opacity: 0.4;
  display: none;
}

.frnHomePreviewInfoLayer_content {
  display: none;
 // font-size: 1.4rem;
  background: #fff;
  opacity: 1;
  border-top: solid 5px #a3db52;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 10%;
 // margin: 150px auto auto auto;
  margin: 70px auto auto auto;
 // width: 660px;
  width: 90%;
  height: 532px;
  z-index: 1234567850;
  padding: 20px 0 0 0;
  font-size: 0.9rem;
  overflow: hidden;
  box-shadow: 5px 5px 10px 0px #333;
  -moz-box-shadow: 5px 5px 10px 0px #333;
  -webkit-box-shadow: 5px 5px 10px 0px #333;
  @include breakpoint(medium up) {
    margin: 270px auto auto auto;
    height: 660px;
  }
  @include breakpoint(large up) {
    width: 660px;
    padding: 30px 0 0 0;
    height: 590px;
    margin: 90px auto auto auto;
  }
  @include breakpoint(xxlarge up) {
    width: 660px;
    margin: 150px auto auto auto;
  }
  @media screen and (max-width: 320px) {
    height: 475px;
  }
}
.frnHomePreviewInfoLayer_info {
  padding: 0px 0px 0px 0px;
  /* border-bottom: solid 3px #000; */
  font-size: 18px;
 // margin-bottom: 12px;
  color: #21314D;
  text-align: center;
  font-family: Open Sans;
  font-weight: 600;
  width: 50%;
  margin: 0 auto;
  @include breakpoint(medium up) {
    font-size: 24px;
    width: 75%;
  }
}
.frnHomePreviewInfoLayer_btnField {
  position: relative;
  height: 37px;
  margin-bottom: 37px;
  margin-top: 25px;
  @include breakpoint(medium up) {
    margin-bottom: 42px;
    margin-top: 30px;
  }
}
.frnHomePreviewInfoLayer_btn_large {
  /* float: right; */
  text-transform: uppercase;
  font-size: 1rem;
  text-align: center;
  font-family: "Open Sans Condensed",Arial,Helvetica,sans-serif;
  position: absolute;
  width: 71%;
  top: 50%;
  left: 50%;
  ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  @include breakpoint(medium up) {
    width: 36.5%;
  }
  @include breakpoint(large up) {
    width: 36.5%;
  }
}
.frnHomePreviewInfoLayer_btn_large a {
  color: #21314D;
  float: left;
  padding: 10px 40px 10px 40px;
  background: #84bc34;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans';
  width: 100%;
}
.frnHomePreviewInfoLayer_cancel {
  color: #84bc34;
  cursor: pointer;
  position: absolute;
  /*top: 79%;
  left: 50%; */
  top: 15px;
  left: 90%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-top: 20px;
  @include breakpoint(large up) {
    left: 94%;
  }
}
.frnHomePreviewInfoLayer_cancel {
  background: #E3E6E8;
  padding: 5px;
  border-radius: 50%;
  padding-left: 6.5px;
  padding-right: 6.5px;
  padding-top: 0px;
  padding-bottom: 4px;
  color: #21314D;
  font-size: 1.5em;
  line-height: 1;
  font-weight: bold;
}
.frnHomePreviewSearchLayerArea {
  width: 100%;
  height: 62px;
  background: #21314d;
  margin-bottom: 20px;
}
.frnHomePreviewSearchLayer {
  width: 50%;
  margin: 0 auto;
  position: absolute;
  top: 33%;
  left: 24%;
}
.frnHomePreviewSearchLayer .frn_headSearch {
  background: #ffffff;
  height: 30px;
}
.frnHomePreviewTextLayer {
  margin-bottom: 10px;
  margin-left: 18px;
  margin-right: 18px;
  padding-top: 25px;
  border-top: 1px solid #C8CCD0;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 18px;
  @include breakpoint(medium up) {
    font-size: 24px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    padding-top: 30px;
  }
  @media screen and (max-width: 320px) {
    font-size: 16px;
  }
}
.frnHomePreviewTeaserLayer {
  padding: 0px 8px 0px 8px;
  clear: both;
  @include breakpoint(medium up) {
    padding: 0px 20px 0px 20px;
  }
}
.test-icon {
  position: relative;

}
.test-icon:before {
  background-image: url(../img/icon_goodnews.png);
  content: "";
  width: 56px;
  height: 56px;
  position: absolute;
  bottom: -6px;
  right: 20px;
  background-size: 100%;
}
#nichtMehr {
  margin-left: 18px;
  margin-top: 18px;
  width: 16px;
  height: 16px;
  @include breakpoint(medium up) {
    margin-top: 35px;
    margin-left: 30px;
  }
  @include breakpoint(large up) {

  }
}
label.nichtMehrText {
  position: relative;
  bottom: 3px;
  font-size: 12px;
}
.frn_btTeaser {
  position: relative;
  float: left;
}
.frnHomePreviewTeaserLayer .frn_btTeaser {
  width: 48%;
  margin-right: 12px;
  margin-bottom: 30px;
}

.frnHomePreviewTeaserLayer .frn_btTeaser.frn_ttyp3.no_bhead.umschaltTeaser:nth-child(3) {
  clear: both;
}
/* left bilder down */
.frnHomePreviewTeaserLayer .frn_btTeaser .bildContainer {
  width: 45.4%;
}
.frnHomePreviewTeaserLayer .frn_btTeaser .frn_text {
  width: 49%;
  margin-top: -20px;
  font-size: 16px;
  font-family: Open Sans;
}
.frn_btTeaser .frn_teaserHeadline {
  margin: -0.2em 0 0 0;
}
.clearBoth {
  clear: both;
}
.frnHomePreviewTeaserLayer #remainderLayerArticles a {
  text-decoration: none;
  font-size: 14px;
  @include breakpoint(medium up) {
  font-size: 14px;
  }
  @include breakpoint(large up) {

  }
  @media screen and (max-width: 320px) {
    font-size: 12px;
  }
}
.frnHomePreviewInfoLayer_btn_large a:hover {
  background: #76aa2c;
}
.frnHomePreviewSearchLayerArea #suchfeld:focus {
  background: white;
  color: grey;
  padding-left: 10px;
  font-size: 14.4px;
}
.frnIframeWrapper {
  padding-top: 63%;
  position: relative;
  overflow: hidden;padding-left: 0;
  padding-right: 0;
  margin-bottom: 30px;
  iframe {
    position: absolute;top: 0;left: 0;width: 100%;height: 100%;
  }
}
.contentTeaser {
  width: 300px;
  float: left;
  margin-right: 2rem;
  margin-left: -59px;
  &-title {
    font-family: "Open Sans Condensed","Arial Narrow",Arial,Helvetica,sans-serif;
    border-bottom:solid 2px #e33ab9;
    margin-bottom:14px;
    text-transform:uppercase;
    font-weight:bold;
  }
  &-text {
    padding-top: 1rem;
    line-height: 1.4;
    a {
      color: #21314D;
      font-weight: normal;
    }
  }
  &-image {
    position: relative;
    &--amazon {
      &:before {
        content:  "";
        position: absolute;
        top: 10px;
        left: 0;
        width: 120px;
        height: 46px;
        background-image: url(../img/amazon_logo_wide.png);
      }
    }
  }
}
.frn-article__right {
  h3, .h3 {
    @include breakpoint(large up) {
      font-size: rem-calc(16);
    }
  }
  h5, .h5 {
    @include breakpoint(large up) {
      font-size: rem-calc(20);
    }
  }
  article {
    position: relative;
    @include breakpoint(large up) {
      margin-bottom: 12px;
    }
    &:before {
      @include breakpoint(large up) {
        content: "";
        position: absolute;
        top: 10px;
        left: 10px;
        background: #e7ebf2;
        width: calc(100% - 20px);
        height: calc(100% - 28px);
      }
    }
  }
  glomex-text-line-clamp {
    background: #e7ebf2;
  }
}

.frn-read-more {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  &-botton {
    color:#fff;
    font-weight:800;
    margin: 70px auto 0 auto;
    display: block;
    text-transform: uppercase;
  }
  &-link {
    color: #84bc34;
    background: none;
    text-transform: none;
    margin: 50px auto 0 0;
    font-size: 0.8rem;
    font-weight: 800;
    padding: 0 0 16px 20px;
    cursor: pointer;
    &-arrow {
      position: relative;
      &:after, &:before {
        content: "";
        position: absolute;
        height: 2px;
        width: 7px;
        top: 8px;
        background: #84bc34;
        border-radius: 0 4px 4px 0;
      }
      &:before {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        -sand-transform: rotate(135deg);
        left: 12px;
      }
      &:after {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        -sand-transform: rotate(-135deg);
        left: 8px;
      }
    }
  }
}

/* Reddit iFrame */
iframe[src*="embed.reddit.com"] {
  height: 500px;
}

#articleNavigation {
  position: relative;
  background: #f9f9f9;
  margin-left: 0;
  border: solid 1px #c1c1c1;
  padding: 11px 11px 4px 50px;
  counter-reset: list-counter;
  overflow: hidden;
  @include breakpoint(medium up) {
    display: inline-block;
    min-width: 560px;
  }
  ol {
    margin: 10px 6px 10px 2.65rem;
  }
  .frn-read-more {
    z-index: 2;
  }
  .frn-read-more-link {
    &:before {
      content: "Gesamtes Inhaltsverzeichnis anzeigen";
    }
  }
  &.more-link {
    padding-bottom: 34px;
    height: 180px;
  }
  &.more {
    height: auto;
    .frn-read-more {
      background: none;
      z-index: 0;
    }
    .frn-read-more-link {
      cursor: pointer;
      &:before {
        content: "Weniger anzeigen";
      }
      &-arrow {
        &:before {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -sand-transform: rotate(45deg);
          left: 12px;
        }
        &:after {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -sand-transform: rotate(-45deg);
          left: 8px;
        }
      }
    }
  }
  li {
    z-index: 1;
    font-size: .9rem;
    margin-bottom: .65rem;
    counter-increment: list-counter;
    &:before {
      top: 2px;
      background: #000;
      width: 18px;
      height: 18px;
      border-radius: 16px;
      margin: 1px 0 0 -33px;
      font-size: 0.6rem;
      font-weight: bold;
      line-height: 1.9;
      content: counters(list-counter, '.');
      color: #fff;
      text-align: center;
    }
    li.art-nav-nested{
      margin-left: 44px;
    }
  }
  a {
    scroll-margin-top: 200px;
    @include breakpoint(medium up) {
      scroll-margin-top: 140px;
    }
  }
}

// Vitrado article widgets
.article-info-box {
  border: solid 1px #ebebeb;
  background: #fafafa;
  padding: 1rem;
  margin-bottom: 1rem;
}
.article-info-box ul {
  list-style-type: disc;
}
.article-info-box li{
  margin-bottom: 0;
}
.article-info-box li:before {
  display: none;
}
.article-cta {
  align-items: center;
  padding: 1rem 1rem 1rem 1.4rem;
  margin-bottom: 1rem;
  border: solid 1px #e8f4d7;
  background: #f4f9eb;
  display:flex;
  justify-content: space-between;
  position: relative;
}
.article-cta p {
  margin-bottom:0;
}
.article-cta:before {
  content:"";
  position:absolute;
  left: 0;
  top: 1rem;
  bottom: 1rem;
  width: 6px;
  background: #84bc34;
}
.button.success.article-cta-button {
  margin-bottom: 0;
  color: #21314d;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
}
.article-cta-link {
  font-weight: bold;
  font-size: 2.5rem;
  color: #84bc34;
}
 #PreviewTeaserPic1, #PreviewTeaserText1,  #PreviewTeaserPic3, #PreviewTeaserText3 {
  display: none;
  @include breakpoint(medium up) {
  display: block;
  }
}
 // liveticker dpa
.tik4-blog, .vg-embed {
  li:before {
    display: none;
  }
}
