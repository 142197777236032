.frn-header {
  min-width: 320px;
  min-height: 0;
  margin: 62px auto 0 auto;
  padding: 0;
  //position: fixed;
  position: relative;
  top: 0;
  width: 100vw;
  background: #ffffff;
  z-index: 102;
  &:before {
    @include breakpoint(large up) {
      content: "";
      width: 1px;
      height: 120px;
      float: left;
    }
  }
  &.minimize{
    &:before{
      display:none;
    }
  }
  &.fixed {
    position: fixed;
    top: -400px;
    #responsive-topBar.active {
      margin-top: 334px;
    }
    &:after {
      @include breakpoint(large up) {
        content: "";
        display:block;
        height: 37px;
      }
    }
  }
  @include breakpoint(medium only) {
    margin: 70px auto 0 0;
    max-width: (map-get($breakpoints, medium));
  }
  @include breakpoint(large up) {
    z-index: 4;
    background-color: #21314D;
    position: relative;
    max-width: 1320px;
    min-height: 0;
    margin-left: 0;
    margin-top: 109px;
    left: 0;
    right: 0;
    width: auto;
  }
  @media print, screen and (min-width: (map-get($breakpoints, xlarge) + ($grid-container-center-shifting*2) + 20)){
    margin-left: auto;
    left: -$grid-container-center-shifting;
    &.fixed {
      margin-left: 0;
      left: calc((50% - 660px) - 150px);
    }
  }
  .title-bar {
    background-color: $primary-color;
    padding: 0.65rem;
    height: 62px;

    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100vw;
    max-width: (map-get($breakpoints, medium));
    @include breakpoint(medium up) {
      height: 70px;
    }
    @include breakpoint(large up) {
      height: 50px;
    }
    .menu-icon {
      margin-bottom: 6px;
    }
    .frnAngeboteMobile {
      position: absolute;
      background-color: #21314d;
      //top: 14px;
      width: auto;
      //height: 40px;
      right: 61px;
      //top: 0;
      //bottom: 0;
      //margin: auto 0;
      @include breakpoint(medium up) {
        //right: 12px;
        //width: 308px;
        //top: 14px;
       // top: 15px;
        //height: 50px;
      }
      button {
        font-size: 14px;
        font-weight: 600;
        color: $black;
        width: 107px;
        height: 32px;
        border-radius: 8px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        position: initial;
        left: 0;
        right: 0;

        padding: 5px 0px 5px 0px;
        background-image: none !important;
        background-repeat: no-repeat;
        //padding-left: 24px;
        background-position-x: 16%;
        background-position-y: 52%;
        transition: none;
        text-transform: uppercase;
       @include breakpoint(medium up) {
         position: initial;
         font-size: 16px;
         width: 290px;
         height: 46px;
         background-position-x: 32%;
        }
      }
    }
  }

  /* new articles layer added */
  #frnNewArticlesCount {
    display: none;
    @include breakpoint(large up) {
      display: block;
      position: absolute;
      top: 69px;
      left: 57px;
      background: #84bc34;
      width: 20px;
      height: 20px;
      border-radius: 14px;
      z-index: 100;
      color: #fff;
      text-align: center;
      font-size: 0.7em;
      line-height: 1.5;
      border: solid 1px #fff;
      cursor: pointer;
    }
  }
  #responsive-topBar {
    background-color: #FFFFFF;
    padding: 0 0 0 0;
    height: 0;
    width: 100%;
    max-width: map-get($breakpoints, xlarge);
    box-shadow: 0 1px 3px 1px rgba(170, 170, 170, .7);
    -webkit-box-shadow: 0 1px 3px 1px rgba(170, 170, 170, .7);
    .channel_bar {
      height: 100%;
      margin-left: 0%;
      max-width: 1320px;
      display: block;
      position: relative;
      @include breakpoint(medium up) {
        max-width: map-get($breakpoints, medium);
        margin-left: 0;
        left: 0;
      }
      @include breakpoint(large up) {
        display: inherit;
        max-width: map-get($breakpoints, xlarge);
        margin-left: 0;
        left: 0;
        padding: 0 1.05rem 0 0rem;
      }
      @media print, screen and (min-width: (map-get($breakpoints, large) + ($grid-container-center-shifting*2) + 20)) {
        padding: 0 0.55rem 0 27px;

      }
      .frnbottombarRight {
        height: 200px;
        @include breakpoint(large up) {
          height: auto;
          margin-left: 10px;
          margin-right: 50px;
          top: 2.5px;
        }
        form#frn_searchTarget {
          height: 34px;
          border: 1px solid #5E666E;
          border-radius: 5px;
          overflow: hidden;
        }
      }
    }
    .frnKopfWetter_head {
      position: absolute;
      right: 336px;
      width: 239px;
      top: -1px;
      background: white;
      height: 39px;
      @include breakpoint(xxlarge up) {
        width: 216px;
      }
    }
    .frnmainbar {
      height: auto;
      padding-bottom: 126px;
      @include breakpoint(large up) {
        height: 100%;
        padding-bottom: 0;
        padding-left: 24px;
        position: static;
        z-index: 1;
      }
    }
    .frnbottombarRight {
      height: 100%;
    }
    .frnbottombarRightItems {
      height: 100%;
      position: static;
    }
    @include breakpoint(large up) {
      background-color: #FFFFFF;
      height: 39px;
      border-bottom: 2px solid #FFFFFF;
    }
  }
  .frntopbar {
    padding: 1.2rem 1rem 0rem 0.2rem;
    background-color: $frncolor-primary;
    height: 109px;
    max-width: 1320px;
    margin: 0 auto;
    position: relative;
    &__menu {
      background-color: $frncolor-primary;
      font-family: 'Open Sans Condensed','Arial Narrow', Arial, Helvetica, sans-serif;
      a {
        font-size: 18px;
        padding: 0;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        text-decoration: none;
        font-family:"Open Sans",Arial,Helvetica,sans-serif !important;
        font-style:normal;
        font-weight:600;
        text-transform: uppercase;
      }
      button {
        font-size: 16px;
        width: 168px;
        height: 50px;
        border-radius: 5px;
        color: rgb(33, 49, 77);
        img {
          margin-right: 2px;
        }
        :hover {
          background-color: #76AA2C;
          color: rgb(33, 49, 77);
        }
      }
      .button.frn-secondaryButton:focus, .button.frn-secondaryButton:hover {
        background-color: #76AA2C;
        color: rgb(33, 49, 77);
      }
      &--googleSuche {
        max-width: 250px;
        height: 32px;
      }
      &--Item {
        color: white;
      }
      &--Item:hover {
        text-decoration: underline;
      }
      &--Item.menutop--weiterefreenetmarken {
        color: $frncolor-startgrey;
      }
    }
    @include breakpoint(medium up) {
      max-width:map-get($breakpoints, medium);
      margin-left: 0;
      left: 0;
    }
    @include breakpoint(large up) {
      max-width:map-get($breakpoints, xlarge);
    }
    @media print, screen and (min-width: (map-get($breakpoints, xlarge) + ($grid-container-center-shifting*2) + 20)){
    }
    #frnKopfLogoLink {
      display: block;
      bottom: 32px;
      left: 11px;
      width: 178px;
      height: 40px;
      top: 30px;
      position: absolute;
    }
  }
  .frn_channel_productbar .channel_bar {
    @include breakpoint(medium up) {
      max-width:map-get($breakpoints, medium);
      margin-left: 0;
      left: 0;
    }
    @include breakpoint(large up) {
      max-width:map-get($breakpoints, xlarge);
      margin-left: 0;
      left: 0;
    }
    @media print, screen and (min-width: (map-get($breakpoints, xlarge) + ($grid-container-center-shifting*2) + 20)){
      margin-left: auto;
      left: -$grid-container-center-shifting;
    }
  }
  .frnbottombar {
    background-color: $primary-color;
    margin-left: 11px;
    @include breakpoint(large up) {
      background-color: white;
      border: none;
      margin-left: 4px;
    }
    div.grid-x {
      padding-top: 0;
      @include breakpoint(large up) {
        padding-top: 17px;
        padding-bottom: 10px;
      }
    }
    .frn_channelSubmenu {
      padding-left: 0;
      @include breakpoint(large up) {
        padding-left: 2rem;
      }
    }
  }
  .frn_channel {
    margin-right: 0px;
    height: 100%;
    margin-top: 2px;
    width: 94%;
    margin-left: 10px;
    padding: 10px 5px 4px 0px;
    border-bottom: 4px solid #fff;
    @include breakpoint(large up) {
      position: static;
      margin-right: 4px;
      padding: 0 2px;
      width: initial;
      margin-left: 0px;
    }
    .frn_channelName {
      padding: 3px 10px 4px 0px;
      text-transform: uppercase;
      &:before{
        @include breakpoint(large up) {
          content: attr(title);
          display: block;
          height: 0;
          font-family: "Arial Narrow", Courier, Arial, Helvetica, sans-serif;
          overflow: hidden;
        }
      }
      &.partnersuche:before{
        @include breakpoint(large up) {
          display: none;
        }
      }
    }
    ul.nested p a {
      line-height: inherit;
      padding: 0;
    }
    &--news { border-bottom: 2px solid $frncolor-news; }
    &--sport { border-bottom: 2px solid $frncolor-sport; }
    &--finanzen { border-bottom: 2px solid $frncolor-finanzen; }
    &--auto { border-bottom: 2px solid $frncolor-auto; }
    &--unterhaltung { border-bottom: 2px solid $frncolor-unterhaltung; }
    &--lifestyle { border-bottom: 2px solid $frncolor-lifestyle; }
    &--digital { border-bottom: 2px solid $frncolor-digital; }
    &--spielen { border-bottom: 2px solid $frncolor-spielen; }
    &--mail { border-bottom: 2px solid $frncolor-mail; }
    &--domain { border-bottom: 2px solid $frncolor-domain; }
    &--internet, &--mobilfunk, &--tv-entertainment, &--mail_cloud, &--alle.angebote, &--kundenservice, &--online-spiele {
      display: block;
      @include breakpoint(large up) {
        display: none;
      }
    }
    &--spielen {
      display: none;
      @include breakpoint(large up) {
        display: block;
      }
    }
    &--partnersuche {
      display: block;
      border-bottom: 2px solid $frncolor-partnersuche;
      .frn_channelName {
        font-size: 14px;
        line-height: 1.8;
        color: #fff;
        padding: 3px 10px 4px 0 !important;
        @include breakpoint(large up) {
          font-size: 1.7rem;
          line-height: .9;
          color: #f0909e;
          padding: rem-calc(4) rem-calc(8) rem-calc(8) rem-calc(10) !important;
        }
        &:after {
          content: "Partnersuche";
          position: absolute;
          left: .6rem;
          background-color: #21314d;
          @include breakpoint(large up) {
            display: none;
          }
        }
      }
    }
    .frn_Nested {
      margin-left: 0;
      margin-top: 2px;
      padding-right: 0px;
      @include breakpoint(large up) {
        margin-top: 0px;
        padding-right: 10px;
      }
      &--news {
        border-top: dotted 1px #ffffff;
        @include breakpoint(large up) {
          border-top: 2px solid $frncolor-news;
          border-bottom: 2px solid $frncolor-news;
        }
      }
      &--sport {
        border-top: dotted 1px #ffffff;
        @include breakpoint(large up) {
          border-top: 2px solid $frncolor-sport;
          border-bottom: 2px solid $frncolor-sport;
        }
      }
      &--finanzen {
        border-top: dotted 1px #ffffff;
        @include breakpoint(large up) {
          border-top: 2px solid $frncolor-finanzen;
          border-bottom: 2px solid $frncolor-finanzen;
        }
      }
      &--auto {
        border-top: dotted 1px #ffffff;
        @include breakpoint(large up) {
          border-top: 2px solid $frncolor-auto;
          border-bottom: 2px solid $frncolor-auto;
        }
      }
      &--unterhaltung {
        border-top: dotted 1px #ffffff;
        @include breakpoint(large up) {
          border-top: 2px solid $frncolor-unterhaltung;
          border-bottom: 2px solid $frncolor-unterhaltung;
        }
      }
      &--lifestyle {
        border-top: dotted 1px #ffffff;
        @include breakpoint(large up) {
          border-top: 2px solid $frncolor-lifestyle;
          border-bottom: 2px solid $frncolor-lifestyle;
        }
      }
      &--digital {
        border-top: dotted 1px #ffffff;
        @include breakpoint(large up) {
          border-top: 2px solid $frncolor-digital;
          border-bottom: 2px solid $frncolor-digital;
        }
      }
      &--spielen {
        border-top: dotted 1px #ffffff;
        @include breakpoint(large up) {
          border-top: 2px solid $frncolor-spielen;
          border-bottom: 2px solid $frncolor-spielen;
        }
      }
      &--mail {
        border-top: dotted 1px #ffffff;
        @include breakpoint(large up) {
          border-top: 2px solid $frncolor-mail;
          border-bottom: 2px solid $frncolor-mail;
        }
      }
      &--domain {
        border-top: dotted 1px #ffffff;
        @include breakpoint(large up) {
          border-top: 2px solid $frncolor-domain;
          border-bottom: 2px solid $frncolor-domain;
        }
      }
      &--partnersuche {
        border-top: dotted 1px #ffffff;
        @include breakpoint(large up) {
          border-top: 2px solid $frncolor-partnersuche;
          border-bottom: 2px solid $frncolor-partnersuche;
        }
      }
      &--news li a:before { background-color: $frncolor-news; }
      &--sport li a:before { background-color: $frncolor-sport; }
      &--finanzen li a:before { background-color: $frncolor-finanzen; }
      &--auto li a:before { background-color: $frncolor-auto; }
      &--unterhaltung li a:before { background-color: $frncolor-unterhaltung; }
      &--lifestyle li a:before { background-color: $frncolor-lifestyle; }
      &--digital li a:before { background-color: $frncolor-digital; }
      &--spielen li a:before { background-color: $frncolor-spielen; }
      &--mail li a:before { background-color: $frncolor-mail; margin-top: 9px;}
      &--domain li a:before { background-color: $frncolor-domain; }
      li:hover {
        background-color: transparent;
        @include breakpoint(large up) {
          background-color: #f2f2f2;
        }
      }
    }
  }
  .frn_product {
    width: 94.5%;
    margin-left: 11px;
    margin-right: 2px;
    margin-top: 4px;
    margin-bottom: 4px;

    position: static;
    @include breakpoint(large up) {
      width: initial;
      margin-left: 0;
      height: 86.5%;
      padding-left: 6px;
      padding-right: 6px;
      a.frn_channelName {
        color:#ffffff;
      }
      ul.frn_Nested {
        width: 100%;
        border-width: 2px 0 2px 0;
        border-style: solid;
      }
      &.prod-1 {
        background: $frncolor-startgreen;
        &:hover {
          background: $frncolor-hovergreen;
        }
        ul.frn_Nested {
          border-color: $frncolor-startgreen;
        }
      }
      &.prod-2 {
        background: $frncolor-startblue;
        &:hover {
          background: $frncolor-hoverblue;
        }
        ul.frn_Nested {
          border-color: $frncolor-startblue;
        }
      }
      &.prod-3 {
        background: $frncolor-startyellow;
        &:hover {
          background: $frncolor-hoveryellow;
        }
        ul.frn_Nested {
          border-color: $frncolor-startyellow;
        }
        &  a {
          color: #08111b;
        }
      }
      &.last {
        margin-right: 8px;
      }

      &.frn_channelKunden {
        margin-top: 0px;
        margin-right: -5px;
        position: absolute;
        right: -34px;
        width: 39px;
        height: 32px;
        top: -1px;
        padding-right: 0;
        padding-left: 16px;
        background: #ffffff;
        a {
          padding: 0rem;
          width:25px;
          height: 25px;
          background: rgb(132, 188, 52);
          position: absolute;
          margin-left: -9px;
          top: 18%;
          img {
            width: 18px; height: 18px; margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    .frn_channelName {
      padding: 10px 5px 4px 0px;
      z-index: 1;
      text-transform: uppercase;
      @include breakpoint(large up) {
        padding: 2px 5px 2px 0px;
      }
    }

  }



  .dropdown.menu>li.frn_channel--news.is-active>a {
    color: #ffffff;
    background-color: $frncolor-newsHover;
  }
  .dropdown.menu>li.frn_channel--sport.is-active>a {
    color: #ffffff;
    background-color: $frncolor-sportHover;
  }
  .dropdown.menu>li.frn_channel--finanzen.is-active>a {
    color: #ffffff;
    background-color: $frncolor-finanzenHover;
  }
  .dropdown.menu>li.frn_channel--auto.is-active>a {
    color: #ffffff;
    background-color: $frncolor-autoHover;
  }
  .dropdown.menu>li.frn_channel--unterhaltung.is-active>a {
    color: #ffffff;
    background-color: $frncolor-unterhaltungHover;
  }
  .dropdown.menu>li.frn_channel--lifestyle.is-active>a {
    color: #ffffff;
    background-color: $frncolor-lifestyleHover;
  }
  .dropdown.menu>li.frn_channel--digital.is-active>a {
    color: #ffffff;
    background-color: $frncolor-digitalHover;
  }
  .dropdown.menu>li.frn_channel--spielen.is-active>a {
    color: #ffffff;
    background-color: $frncolor-spielenHover;
  }
  .dropdown.menu>li.frn_channel--mail.is-active>a {
    color: #ffffff;
    background-color: $frncolor-mailHover;
  }
  .dropdown.menu>li.frn_channel--domain.is-active>a {
    color: #ffffff;
    background-color: $frncolor-domainHover;
  }
  .dropdown.menu>li.frn_channel--partnersuche.is-active>a {
    background-color: $frncolor-partnersucheHover;
  }
  .dropdown.menu>li.is-active>a {
    color: #ffffff;
  }
  a.frn_channelName {
    color: rgb(255, 255, 255);
    font-size: 14px;
    line-height: 1.8;
    letter-spacing: 0.5px;
    padding-left: 0;
    @include breakpoint(large up) {
      color: #21314d;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.8;
      font-family: 'Open Sans Condensed','Arial Narrow', Arial, Helvetica, sans-serif;
      margin-right: -2px;
      padding-left: 0.148rem !important;
      padding-right: 0.148rem !important;
      text-align: center;
    }

  }
  .frn-wetter {
    //color: #ffffff;
    color: rgb(90, 99, 116);
    font-size: 16px;
    font-family: "Open Sans Condensed", "Arial Narrow", "Open Sans", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: bold;
    padding-left: 4px;
    padding-top: 6px;
    height: 35px;
    overflow: hidden;
    @include breakpoint(medium up) {
      padding-top: 7px;
      padding-left: 18px;
    }

  }
  li {
    font-size: 16px;
    margin-bottom: 0;
    @include font-bold(800);
    .fi-plus, .fi-minus {
      position: absolute;
      right: 15px;
      bottom: 12px;
      @include breakpoint(large up) {
        display: none !important;
      }
    }
  }
  .is-accordion-submenu-parent {
    border-bottom: dotted 1px #ffffff;
    a, li {
      font-size: 16px;
      @include font-bold(800);
    }
  }
  .horizontal-center {
    position: absolute;
    right: 0;
    //left: 19%;
    left: 18%;
    width: 79.9%;
    top: 20%;
    ul.frntopbar__menu {
      li {
        position: relative;
        div {
          color: #FFFFFF;
          padding: 5px 17px 17px 17px;
          margin: 0;
          position: absolute;
          top: 37px;
          line-height: 1.4;
          font-size: 14px;
          visibility: hidden;
          right: 0;
          left: 0;
        }
      }
    }
    ul.frntopbar__menu li .frntopbar__menuContent {
      font-size: 14px;  line-height: 1.4;
      color: #fff;
      text-align: center;
      text-decoration: none;
      font-style: normal;
      font-weight: 800;
      text-transform: none;
      background: none;
      font-family: "Open Sans Condensed", "Arial Narrow", Arial, Helvetica, sans-serif !important;
    }
    ul.frntopbar__menu li:hover div{
      visibility: visible;
    }
    ul.frntopbar__menu li:hover a:not(:last-child){
      margin-bottom: 13px;
      text-decoration: underline;

    }
    ul.frntopbar__menu li.hover_active {
      background: rgb(132, 188, 52);
      margin-top: -22px;
      cursor: pointer;
      border-left: none;
      padding: 0 39px;
    }
    ul.frntopbar__menu li.hover_active + li {
      border-left: none;
    }
  }
  .active_menuProduct ul.frntopbar__menu li.hover_active div {
    height: 62px;
    background: rgb(132, 188, 52);
  }
}
.logout, .imagegallery {
  .frn-header:before {
    display: none;
  }
}
#channelNav_tipptrainer {
  display: none;
  @include breakpoint(large up) {
    display: block;
  }
}
.frn-header ul li button.submenu-toggle {
  z-index: 3;
}
.frn-header .dropdown.menu.large-horizontal>li.is-dropdown-submenu-parent>a:after, .frn-header ul li button.submenu-toggle:after, .frn-header ul li button.submenu-toggle:before {
  border-top-color: transparent;
  display: none;
}
.frn-header a.frn_nestedMenu {
  padding: .4rem 1.5rem !important;
  font-size: 14px;
  color: white;
  @include font-bold(800);
  @include breakpoint(large up) {
    font-size: 16px;
    color: #21314d;
    font-family: 'Open Sans Condensed','Arial Narrow',Arial,Helvetica,sans-serif;
    text-transform: uppercase;
  }
}

.frn-header .accordion-menu li {
  @include breakpoint(large up) {
    width: auto;
  }
}
.frn-header ul li:before { display: none; }
// flyout
.frn-header ul.nested li a:before {
  display: none;
  @include breakpoint(large up) {
    display: block;
    content: "";
    height: 10px;
    width: 10px;
    margin-left: -19px;
    margin-right: 4px;
    position: absolute;
    margin-top: 2px;
  }
}
.frnkopfLogo::before {
  content: '';
  position: absolute;
  width: 135px;
  height: 45px;
  top: 7.6px;
  left: 2%;
  background: url('../img/freenet-Logo.svg') no-repeat left center;
  background-position-x: left;
  background-size: auto;
  background-size: 131px;
  background-position-x: 2px;
  @include breakpoint(medium up) {
    top: 12.6px;
  }
  @include breakpoint(large up) {
    width: 158px;
    height: 75px;
    top: 12px;
    left: 20px;
    background-size: 154px;
  }
}
.frntopbar__menu li.frnProduct {
  line-height: 58px;
  border-left: 1px solid rgb(231, 235, 242);
  text-align: center;
  display: inline-block;
  padding: 0 38px;
}

.frntopbar__menu li.frnProduct.angebote {
  padding-right: 0;
  padding-left: 44px;
  margin-right: -20px;
}

// deprecated ...
.frntopbar__menu li.frnProductAngebote {
  border-left: 1px solid #e7ebf2;
  padding: 4px 0 0 17px;
}

.frntopbar__menu li.googleSearch {
  width: 285px;
}
#suchfeld {
  background: url("../img/googlelogo-farbe0053.png") no-repeat 14px 8px rgb(255,255,255);
  border: none;
}
#suchfeld:placeholder-shown, #suchfeld_preview:placeholder-shown {
  background: url("../img/googlelogo-farbe0053.png") no-repeat 14px 5px rgb(255,255,255);
  border: none;
  box-shadow: none;
  height: 30px;
}
/* header on top */
#frnKopfBlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 114px;
  background: #21314d;
  z-index: 0;
  box-shadow: 0 5px 5px -5px rgba(170,170,170,0.7),0 5px 5px -5px rgba(170,170,170,0.7);
}
#frnKopfBlock i {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 39px;
  background: #fff;
  border-bottom: solid 2px #ececec;
  left: 0;
}
/* fly out from mail, webhosting and cloud */
.frnFlyoutContainer .grid-x {
  position: absolute;
  top:0;
  z-index: 110;
  height:100%;
  width: 100%;
}

.frnMailFlyoutContainer, .frnWebHostingFlyoutContainer, .frnPrimeDayFlyoutContainer {
  text-transform: none;
  font-family: "Open Sans",Arial,Helvetica,sans-serif;
  font-weight: bold;
}
.frnMailFlyoutContainer{background:url("../img/background.jpg") no-repeat;background-position-x:-100px;background-position-y:-100px;color:#21314d;}
.frnMailFlyoutCont1 { padding-left: 32px; }
.frnMailFlyoutCont1Headline { font-size: 32px; font-weight: 100; margin-bottom: 15px; line-height: 1.3 }
.frnMailFlyoutCont2, .frnMailFlyoutCont3{margin-top:25px;text-align:center; border-right: 2px solid white;}
.frnMailFlyoutCont3 { border-right: 2px solid white; }
.frnMailFlyoutCont2 a:hover,.frnMailFlyoutCont3 a:hover{text-decoration:none;}
.frnMailFlyoutCont2Headline , .frnMailFlyoutCont3Headline{font-size:20px;}
.frnMailFlyoutCont2HeadlineWiFi{padding:0 0 10px 0;font-size:18px;font-weight:normal}
.frnMailFlyoutCont2Button{width:260px;height:47px;padding:2px 0 8px 0;margin:28px 15px 0 15px;font-size:16px;font-weight:100;color:#ffffff;background-color:#84bc34;}
.frnMailFlyoutCont2Button:hover , .frnMailFlyoutCont3Button:hover{background-color:#76aa2c;}
.frnMailFlyoutCont2Button li, .frnMailFlyoutCont3Button li {float:left;text-transform:unset!important;}
.frnMailFlyoutCont2Button li:after, .frnMailFlyoutCont3Button li:after{content: "\00BB";height: 25px;width: 22px;position: relative;top: 1px;left: 7px;font-size: 24px;font-weight: 100;}
.frnMailFlyoutCont3HeadlineWiFi{padding:0 0 26px 0;font-size:18px;font-weight:normal;}
.frnMailFlyoutCont3Button{width:260px;height:47px;padding:2px 0 8px 0;margin:12px 15px 0 15px;font-size:16px;font-weight:100;color:#ffffff;background-color:#84bc34;}
.frnMailFlyoutCont4{float:left;width:290px;height:240px;margin-top:50px;text-align:center;}
.frnMailFlyoutCont4Headline{width:290px;padding:0 0 28px 0;font-size:20px;}
.frnMailFlyoutCont2Button, .frnMailFlyoutCont3Button, .frnMailFlyoutCont4Button {
 ul {
   margin: 0;
   background: inherit;
 }
 li {
   float: none;
 }
}
.frnMailFlyoutCont1 li a {
  font-size: 20px!important;
  font-weight: 100!important;
  float: left!important;
  width: auto!important;
  color: #21314d!important;
  line-height: 1;
  //  line-height: 0.5;
}
.frnMailFlyoutCont1 li a:after {
  content: "\00BB";
  position: relative;
  top: 1px;
  left: 7px;
  font-size: 26px;
  font-weight: 100;
  color: #84bc34;
}
.frnMailFlyoutCont3 picture.flyout {
  min-height: 0;
}
.frnMailFlyoutCont2 picture.flyout {
  min-height: 0;
}
picture.flyout img {
  width: auto;
}

.frn-header .dropdown.menu>li.is-active.frn_channelWebhosting>a {
  color: #08111b;
}

/* foundation css twick */
@media print, screen and (min-width: 82.5em) {
  .dropdown.menu.large-horizontal>li.opens-right>.is-dropdown-submenu {
    padding-left: -16px;
  }
}
.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0;
}
.dropdown .is-dropdown-submenu a {
  padding: .2rem 0;
}
#frnKopfLogoLinkMobile {
  display: block;
  bottom: 57px;
  width: 130px;
  height: 40px;
  position: absolute;
  top: 6px;
  left: 9px;
}
.frnEmailnone {
  display: none;
  @include breakpoint(medium up) {
    display: block;
  }
}
.frnEmailshow {display: block;}
.hamburger-menu::after {
  top: 4px;
  left: -5px;
  width: 131%;
}
.frn-header.active {
  height: 174px;
  @include breakpoint(medium up) {
    height: 202px;
  }
   @media only screen and (min-device-width: 768px) and (max-device-width: 819px)  {
     height: 195px;
  }
}
#responsive-topBar.active {
  position: fixed;
  right: 0;
  top: 62px;
  background-color: #21314d;
  padding: 0;
  z-index: 999;
  overflow-y: auto;
  height: calc(100vh - 62px);
  width: 93%;
  margin-left: auto;
  max-width: 350px;
  margin-right: 0;
  display: block !important;
  @include breakpoint(medium up) {
    right: auto;
    left: 418px;
  }
}

.frn_product .submenu-toggle { display: none; }
img.plus, img.minus { display: none;}
@media screen and (max-width: 1112px) {
  #responsive-topBar { display: none; }
  .frnmainbar .frn_channel a img
  {
    width: 20px;
    height: 16px;
    display: block;
    position: absolute;
    left: 91%;
    top: 18px;
    z-index: auto;
  }
  .frnmainbar .frn_channel.active a img.plus
  {
    display: none;
  }
  .frnmainbar .frn_channel.active a img.minus
  {
    top: 18px;
  }
  .icon-search-mobilehamburger-button
  {
    width: 18px;
    padding-top: 2px;
    margin-left: 6px;
  }
  .frn_weather_icon_header_outer .cls-1,.frn_weather_icon_header_outer .cls-2 {
    stroke:  rgb(90, 99, 116) !important;
  }
}
@media screen and (max-width: 1320px) {
  .hamburger-button, .hamburgerClose-button {
    width: 16px;
    height: auto;
  }
}
// flyout fix for large Screens
@media screen and (min-width: 1640px) {
  .dropdown.menu.large-horizontal>li.opens-right>.is-dropdown-submenu {
    width: 1320px;
    z-index: 200;
  }
}
.dropdown.menu.large-horizontal>li.opens-right>.is-dropdown-submenu {
    z-index: 200;
  /* newly addeed */
  width: 100%;
  }

// google search bar placeholder background image hide on focus
.frn-header #suchfeld:focus {
  background: #FFFFFF;
  color: #808080;
  padding-left: 10px;
  font-size: 14.4px;
}

// flyout webhosting edited
.flyoutWebhosting {
  padding-left: 18px;
}
.flyoutWebhosting .flyoutWebhostingTitle {
  color: white;font-weight: 400;font-size: 32px;
}
.flyoutWebhosting .flyoutWebhostingSubtitle {
  color: white;font-weight: 400;font-size: 26px;
}
.flyoutWebhosting .large-3.area_fullservice, .flyoutWebhosting .large-4.area_selfservice {
  width: 29.5%;
  position: relative;
}
.flyoutWebhosting .area_fullservice img {
  position: absolute;
  top: 27%;
  left: 39%;
  transform: translate(-50%, -50%);
  width: auto;
}
.flyoutWebhosting .area_selfservice img {
  position: absolute;
  top: -16%;
  left: 30%;
  transform: translate(-50%, -50%);
}
.buttonText {
  width: 75%;
  position: absolute;
  top: 65%;
  left: 38.5%;
  transform: translate(-50%, -50%);
}
.flyoutWebhosting .buttonText a.linkButton {
  margin: 0;
  height: auto;
  padding: 0.76em;
  font-size: 18px;
  font-weight: normal;
}
.flyoutWebhosting .buttonText a.greenButton {
  background-color: #84bc34;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  padding: 0.584em;
}
.flyoutWebhosting .buttonText a.greenButton:hover {
  background-color: #76aa2c;
}
.flyoutWebhosting .area_selfservice form {
  position: absolute;
  top: 65%;
  left: 25%;
  transform: translate(-50%, -50%);
}
.flyoutWebhosting .area_selfservice form img {
  width: 26px; height: 26px; margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.flyoutWebhosting .area_selfservice form a {
  padding: 0rem;
  width:39px;
  height: 39px;
  background-color: #84bc34;
  position: absolute;
  margin-left: 1px;
  display: inline-block;
}
.flyoutWebhosting .area_selfservice form a:hover {
  background-color: #76aa2c;
}
.flyoutWebhosting .large-3.area_fullservice {
  width: 29.5%;
}
.flyoutWebhosting .large-4.area_selfservice {
  width: 36.5%;
}
.flyoutWebhosting .flyoutWebhostingTitle {
  font-weight: 600;
  font-size: 24px;
}
.flyoutWebhosting .flyoutWebhostingSubtitle {
  font-weight: 600;
  font-size: 48px;
}
.flyoutWebhosting .buttonText {
  width: 94%;
  left: 50.5%;
}
.flyoutWebhosting .buttonText a.greenButton {
  color: #092043;
  padding: 1.431em;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.frnFlyoutContainer .flyoutWebhosting.grid-x {
  height: 92%;
}
.flyoutWebhosting #wish-domain-box {
  margin-top: 47px;
}
.flyoutWebhosting #wish-domain-input {
  background: #fff;
  height: 54px;
  float: left;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  max-width: 312px;
  margin-right: 0;
  padding-left: 38px;
}
.flyoutWebhosting #btn-check.linkButton {
  height: 54px;
  width: 96px;
  float: left;
  padding: 15px 0;
  margin-left: 0;
  font-size: 16px;
  background-color: #84bc34;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.flyoutWebhosting #btn-check.linkButton .buttonText {
  float: left;
  position: relative;
  font-weight: 700;
  margin-left: 14px!important;
  text-transform: uppercase;
  margin-top: -2px;
}
#frnWetterSucheBlock {
  background: white;
  height: 54px;
  width: 100%;
  z-index: 2;
  max-width: (map-get($breakpoints, medium));
  .frn-suche {
    padding-top: 12px;
  }
}

.frn-header.active .frn_channel_productbar.active .frnbottombar  {
  margin-left: 0;
}
.frn-header .frn_channel.frn_channel--domain .frn_Nested {
  padding-right: 0;
}
.horizontal-center .frntopbar__menu.icon-right {
  display: flex;
}
.frn-header .frntopbar__menu .angebote button {
  background-image: url("../img/Icon-Hub.svg");
  background-repeat: no-repeat;
  padding-left: 45px;
  background-position-x: 12%;
  background-position-y: 52%;
}

#glomex-player-dock-target{
   position: absolute;
   width:312px;
   height:auto;
   aspect-ratio:16/9;
   right:10px;
   bottom:0;
   visibility: hidden;
   opacity:0;
   pointer-events:none;
   @include breakpoint(large) {
     width:330px;
   }
  /* border:solid 1px blue; */
 }

.hamburger-menu {
  position: absolute;
  right: 11px;
  top: 0px;
  bottom: 0px;
  height: 20px;
  margin: auto 0px;
  z-index: 100;
  @include breakpoint(medium up) {
    right: 27px;
  }
}
.hamburgerClose-button, .hamburger-button {
  filter: brightness(10);
}
.hamburgerClose-button { display: none; }