/* all projects use a central fonts folder on tls.freenet.de */
@font-face{
  font-family:'Open Sans';
  font-style:normal;font-weight:400;font-display:swap;
  src:url('https://tls.freenet.de/fonts/OpenSans-Regular-400.woff2') format('woff2'),
    url('https://tls.freenet.de/fonts/OpenSans-Regular-400.woff') format('woff')
}
@media print,screen and (min-width:80em) {
  @font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal; font-weight: 700; font-display: swap;
    src: url('https://tls.freenet.de/fonts/OpenSansCondensed-Bold-700.woff2') format('woff2'),
    url('https://tls.freenet.de/fonts/OpenSansCondensed-Bold-700.woff') format('woff')
  }
}
@font-face{
  font-family:'Open Sans Extrabold';
  font-style:normal;font-weight:normal;font-display:swap;
  src:url('https://tls.freenet.de/fonts/OpenSans-ExtraBold-800.woff2') format('woff2'),
  url('https://tls.freenet.de/fonts/OpenSans-ExtraBold-800.woff') format('woff')
}
@font-face{
  font-family:'fn-icons';
  font-style:normal;font-weight:normal;font-display:swap;
  src:url('https://tls.freenet.de/fonts/fn-iconsa.woff2') format('woff2'),
  url('https://tls.freenet.de/fonts/fn-iconsa.woff') format('woff')
}