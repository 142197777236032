.frn-footer {
  background-color: $primary-color;
  position: relative;
  min-width: 320px;
  contain: content;
  @include breakpoint(medium down) {
    text-align: center;
    line-height: 2.5;
  }
  @include breakpoint(large up) {
    margin-left: 0;
    left: 0;
  }
  @media print, screen and (min-width: (map-get($breakpoints, large) + ($grid-container-center-shifting*2) + 20)){
    margin-left: auto;
    left: -$grid-container-center-shifting;
  }
  .footerSection {
    @include breakpoint(medium down) {
      padding-top: 0px;
      padding-left: 0px;
    }
    @include breakpoint(large up) {
      padding-top: 40px;
      padding-left: 18px;
    }
  }

  a {
    color: rgb(255, 255, 255);
    font-size: 14px;
    line-height: 1.8;
    letter-spacing: 0.5px;
    :hover {
      color: white;
    }
  }
  h3, .h3 {
    color: rgb(255, 255, 255);
    font-size: 22px;
    font-family: 'Open Sans Extrabold','Arial Narrow',Arial,Helvetica,sans-serif;
    @include font-bold(800);
    line-height: 2;
    letter-spacing: 0.5px;
    @include breakpoint(small only) {
      line-height: 3;
    }
  }
  ul {
    margin-bottom: 0rem;
    li {
      @include breakpoint(medium up) {
        margin-bottom: 0.2rem;
      }
      &:before {
        display:none;
      }
    }
  }
  &-list {
    line-height: 0;
    @include breakpoint(medium up) {
      line-height: 4;
    }
    @include breakpoint(large up) {
      column-count: 2;
      line-height: 0;
    }
  }
}

/* line-height: 4;  tablet ma rakhne*/
/* mobile ma yo narakhne feri */

#frnIcra {
  min-width: 320px;
  padding: 20px 0 45px 0;
  text-align: center;
  position: absolute;
  margin-left: 50%;
  transform: translate(-50%, 0);
  color: rgb(33, 49, 77);
  font-family: 'Open Sans','Arial Narrow', Arial, Helvetica, sans-serif;
  font-size: 12px;
  @include breakpoint(large up) {
    margin-left: 0;
    left: map-get($breakpoints, large)/2;
  }
  @media print, screen and (min-width: (map-get($breakpoints, large) + ($grid-container-center-shifting*2) + 20)){
    margin-left: 50%;
    left: -$grid-container-center-shifting;
  }
  .jusprog {
    margin: 3px;
  }
}
.bottom-image-footer {
 // position: absolute;
background-image: url("../img/footersealsdesktop.png");
  width: 228px;
  height: 57px;
 // background-position: 50px 150px;
}
.top-image-footer  {
 // position: absolute;
  background-image: url("../img/footersealsdesktop.png");
  width: 207px;
  height: 57px;
//  margin-bottom: 70px;
  background-position: 206px 115px;
  margin-top: 50px;
}
