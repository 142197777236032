.imagegallery {
  @include breakpoint(medium up) {
    &:before {
      content: "";
      position: fixed;
      z-index: 2;
      background: #000;
      background: rgba(33, 49, 77, 0.6);
      filter: Alpha(Opacity=60);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  #frnMain {
    @include breakpoint(medium up) {
      //top: -20px;
      overflow: visible;
    }
    @include breakpoint(large up) {
      max-width: 1020px;
      margin-top: 120px !important;
    }
    @include breakpoint(small only) {
      overflow:hidden;
      margin-top: 30px;
    }
  }
  #newarticlecircledot {
    display: none;
  }
  .frn-header {
    @include breakpoint(medium up) {
      z-index: 2;
      min-height: 0;
      //margin-bottom: -100px;
      box-shadow: none;
      //top: 0;
      &.fixed {
        position: absolute;
        top: 4px;
        &:before {
          display:none;
        }
      }
      #frnWetterSucheBlock {
        position: relative;
      }
      .top-bar, .title-bar, #frnWetterSucheBlock {
        z-index: 3;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          z-index: 5;
          width: 100%;
          height: 100%;
          background: #000;
          background: rgba(33,49,77,.6);
          filter: Alpha(Opacity=60);
        }
      }
      .title-bar {
        z-index: 0;
      }
      #responsive-topBar {
        border: none;
        top: 16px;
        z-index: 5;
        position: relative;
      }
    }
    @include breakpoint(large up) {
      position: absolute;
      top: 4px;
    }
  }
  #frnBanner {
    @include breakpoint(large up) {
      z-index: 6;
      position: relative;
      background: transparent;
      top: -108px;
      padding-top: 15px;
      //margin-bottom: -168px;
      margin-bottom: -139px;
      //top: 52px;
      min-height: 120px;
      max-width: 1020px;
      @media print, screen and (min-width: (map-get($breakpoints, xlarge) + ($grid-container-center-shifting*2) + 20)){
        max-width: 10000px;
      }
    }
  }
  #frnAdSky {
    @include breakpoint(large up) {
      right: 282px;
    }
    @media print, screen and (min-width: (map-get($breakpoints, xlarge) + ($grid-container-center-shifting*2) + 20)){
      right: 130px;
    }
  }
  .frn-footer {
    display:none;
  }
  .slick-prev, .slick-next {
    display: none;
    overflow: hidden;
    @include breakpoint(medium up) {
      display: block;
    }
  }
  .slick-prev:before, .slick-next:before {
    @include breakpoint(medium up) {
      display: inline-block;
    }
  }
}

.figure-portrait{
  width: 300%;
  text-align: center;
  margin-left: -100%;
}

.relatedPictureShow {width: calc(125vh - 400px);max-width:460px;position: absolute;left: 50%;top: 50%;text-align:left;font-size:1rem;transform: translate(-50%, -50%);}
.relatedPictureShow img {margin-bottom:12px;width:auto;}
.relatedPictureShowHead {display:block;margin-bottom:10px;border-bottom:solid 2px #333;text-transform:uppercase; text-align: left;font-weight: 700;font-size: 1rem;}
.relatedPictureShowCont {
  &> img, picture img {
    visibility: hidden;
    width: calc(170vh - 400px);
  }
}

.frn_pictureShow {
  position: relative;
  &--most-visited {
    div.cell:nth-child(n+3) {
      display:none;
    }
  }
  article {
    p {
      font-size: 1rem;
      min-height: 1px;
    }
  }
  img {
    @include breakpoint(medium up) {
      width: calc(150vh - 414px);
      margin: auto;
    }
  }
  .carousel {
    figure {
      @include breakpoint(large up) {
        min-height: 150px;
      }
    }
    section {
      &:before {
        content:attr(data-count);
        position:absolute;
        right: 6px;
        top: 3px;
        font-size: rem-calc(14);
        font-weight: bold;
        @include breakpoint(medium up) {
          top: -1px;
          right: 18px;
        }
      }
    }
  }
}
.frn_pictureShow  .frn_BsBack {
  display: none;
  position: absolute; top: 0px; right: 0; width: 30px; height: 30px; z-index: 200; padding-left: 30px; box-sizing: border-box; -moz-box-sizing: border-box; overflow: hidden; background: #84bc35;
  @include breakpoint(medium up) {
    top: 0;
    display: block;
  }
  @include breakpoint(large up) {
    right: 0;
    top: 0;
  }
}
.frn_BsBack:before, .frn_BsBack:after {
  content: ""; position: absolute; height: 3px; width: 25px; top: 13px; left: 3px; background: #fff; overflow: hidden;
}
.frn_BsBack:before {
  transform: rotate(45deg); -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); -sand-transform: rotate(45deg);
}
.frn_BsBack:after {
  transform: rotate(-45deg); -webkit-transform: rotate(-45deg); -ms-transform: rotate(-45deg); -sand-transform: rotate(-45deg);
}
#frnArtCopyrightPictureShowImage {
  color: #808080;font-size: 0.8em;width: calc(100% - 80px);text-align: right;
  @include breakpoint(medium up) {
    width: 87%;
  }
}