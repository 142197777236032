/* Sport LiveTicker */
.frn_sport_ticker_info, .frn_StartSportLiveTicker .frn_t_display {width: 50%;float: left;background: #21314d;height: 100%;}
.frn_StartSportLiveTicker .frn_t_display {position:relative;height: 100%;background-color: #e7ebf2;overflow:visible !important;z-index: 5;}
.frn_StartSportLiveTicker .frn_t_display ul {height:100px; width:100%;margin-left: 0;}
.frn_StartSportLiveTicker .frn_t_display ul li {position:relative; counter-reset: list-counter;margin: 0;}
.frn_StartSportLiveTicker .frn_t_display ul li:before{display: none;}
#frnStartSportLiveTickerLnk, #frnStartSportLiveTickerFormel1Lnk {padding: 7px 13px;font-size: rem-calc(15);background-color: #84bc34;text-transform: uppercase;color: #fff;}
#frnStartSportLiveTickerLnk:hover, #frnStartSportLiveTickerFormel1Lnk:hover {background-color: #4b4b4b;text-decoration: none;;}
.frn_sport_ticker_info_btn {position: absolute;bottom: 14px;left: calc(50% - 120px);z-index: 10;}
.frn_sport_ticker_info_name {position: relative;font-size: rem-calc(20);text-transform: uppercase;padding-left: 20px;top: 6px;color: #fff;white-space: nowrap;z-index: 10;}
.frn_StartSportLiveTicker {
  position: relative;
  overflow:hidden;
  background-color: #21314d;
  width: 100%;
  height: 120px;
  z-index:5;
  border-bottom: solid 20px #fff;
  .frn_t_time_info, .frn_t_time_info_started {
    position: absolute;
    left: calc(-100% + 19px);
    top: 30px;
    width: 95%;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.1em;
    @include breakpoint(medium only) {
      //left: -188px;
      //top: 36px;
    }
    @include breakpoint(large up) {
      left: -288px;
      top:  63px;
    }
  }
}
.frn_StartSportLiveTicker .frn_t_status_not-started .frn_t_time_info_started {display:none;}
.frn_StartSportLiveTicker .frn_t_time_info_started {display:block;}
.frn_StartSportLiveTicker.frn_formel1Ticker .frn_t_time_info, .frn_StartSportLiveTicker.frn_formel1Ticker .frn_t_time_info_started {
  top: 80px;
  font-size:0.6rem;
  @include breakpoint(large up) {
    top: 70px;
    font-size:1rem;
  }
}
.frn_StartSportLiveTicker .frn_t_time_info strong {font-weight: normal;}
.frn_StartSportLiveTicker .frn_t_team.frn_t_team_away {float:right;margin-top:20px;margin-right:4%;text-align: right;}
.frn_StartSportLiveTicker .frn_t_team.frn_t_team_home {margin-top:20px;margin-left:4%;}
.frn_sport_ticker_competition_name {
  float: left;
  margin-left: 18px;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2rem;
  @include breakpoint(large up) {
    float: right;
    margin-right: 10px;
    font-size: 1.4em;
    margin-top: 3px;
  }
}
.frn_StartSportLiveTicker .frn_t_person {float:left;width:94px;text-align:center;margin-top:4px;position:relative;}
.frn_StartSportLiveTicker .frn_t_person:before {content: counter(list-counter);counter-increment: list-counter;position:absolute;left:50%;margin-left:-44px;font-weight:bold;font-size:1rem;}
.frn_StartSportLiveTicker .frn_t_person span{width:56px;height:56px;overflow:hidden;display:block;margin:0 auto;}
.frn_StartSportLiveTicker .frn_t_person div {margin:3px 0 -6px;}
.frn_StartSportLiveTicker .frn_t_person span img {margin-left:-6px;}
.frn_StartSportLiveTicker .frn_t_result_info {position: absolute;width: 92%;left: 4%;text-align: center;font-size: rem-calc(60);top: 0px;color: #21314d;height: 100%;z-index:4;}
.frn_t_result_trenner {display: block;width: 6%;background-color: transparent;margin-left: 2%;margin-right: 2%;float: left;height: 100%;}
.frn_t_result_trenner:before{content:":";}
.frn_t_result_home, .frn_t_result_away {display: block;float: left;width: 45%;text-align: right;}
.frn_t_result_away {text-align: left;}
.frn_StartSportLiveTicker .frn_t_team span {display:block;width:50px;height:46px;margin:0 0 0 auto;margin-bottom:10px;}
.frn_StartSportLiveTicker .frn_t_team.frn_t_team_home span {margin:0 auto 0 0;margin-bottom: 10px;}
.frn_StartSportLiveTicker .frn_t_team strong {white-space:nowrap;font-size: rem-calc(14);font-weight: normal;text-transform: uppercase;}
.frn_StartSportLiveTicker .frn_t_time_info {display:none;}
.frn_StartSportLiveTicker .frn_t_status_not-started .frn_t_time_info {display:block;}
.frn_StartSportLiveTicker .frn_t_result_info strong {font-size:12px; display:none;}
.frn_StartSportLiveTicker .frn_t_team {float:left;width:40%;}
.frn_StartSportLiveTicker .slick-list {overflow: visible;}




@media screen and (max-width: 520px) {
  #frnStartSportLiveTicker.co36 {background-size: 14%;background-position:38% center;}
}
@media screen and (max-width: 414px) {
  .frn_sport_ticker_info_btn {
    left: 20px;
  }
  /*
  .frn_sport_ticker_info::before {
    content: '';
    width: 26%;
    height: 47%;
    position: absolute;
    bottom: -12px;
    left: 8px;
    text-align: right;
    padding: 0px 11px 0px 0px;
    background-color: #333;
    z-index: -1;
  }
   */
}

@media screen and (max-width: 420px) {
  .frn_sport_ticker_info {position:relative;z-index:1000;}
  .frn_StartSportLiveTicker.frn_formel1Ticker .frn_sport_ticker_info {z-index: 1;}
  .frn_StartSportLiveTicker {height:166px;}
  .frn_StartSportLiveTicker.frn_formel1Ticker {height:195px;}
  .frn_sport_ticker_info, .frn_StartSportLiveTicker .frn_t_display {width:100%;}
  .frn_sport_ticker_info_btn {
    left: auto;
    right: 4px;
    top: 8px;
  }

  #frnStartSportLiveTickerLnk:hover, #frnStartSportLiveTickerFormel1Lnk:hover {
    background-color: transparent;
    text-decoration: none;
  }

  #frnStartSportLiveTickerLnk::before, #frnStartSportLiveTickerFormel1Lnk::before {
    content: '';
    width: 29%;
    height: 40px;
    position: absolute;
    top: -9px;
    right: -3px;
    text-align: right;
    padding: 0px 11px 0px 0px;
    background-color: #333;
    z-index: -1;
  }

  .frn_sport_ticker_info {height:42px;}

  .frn_formel1Ticker .frn_sport_ticker_info {height:62px;}
  .frn_sport_ticker_competition_name {float: none;display: block;}
  .frn_StartSportLiveTicker .frn_t_person {margin-top:10px;}
  .frn_StartSportLiveTicker .frn_t_time_info, .frn_StartSportLiveTicker .frn_t_time_info_started {
    left: 20px;
    top: 1px;
    font-size: .8rem;
    color: #21314d;
  }
  .frn_StartSportLiveTicker.frn_formel1Ticker .frn_t_time_info, .frn_StartSportLiveTicker.frn_formel1Ticker .frn_t_time_info_started {font-size:.8rem;padding-right:6px;text-align:right;top:-21px;color:#fff;}
  .frn_StartSportLiveTicker .frn_t_person {width:33%;}
  .frn_t_result_home, .frn_t_result_away {margin-top:-4px;}

  #frnStartSportLiveTicker.co36 {background-size: 12%;background-position: 53% 2px;}
}

@media screen and (max-width: 360px) {
  #frnStartSportLiveTickerLnk::before, #frnStartSportLiveTickerFormel1Lnk::before {
    width: 31%; }
}
@media screen and (max-width: 320px) {
  #frnStartSportLiveTickerLnk::before, #frnStartSportLiveTickerFormel1Lnk::before {
    width: 34%; }
}