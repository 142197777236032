body {
  @include breakpoint(large only) {
    zoom: 0.85;
  }
}


li {
  font-size: $small-font-size;
  margin-bottom: $list-item-margin;
}

ul {
  li:before {
    background-color: $frncolor-secondary;
    content: "";
    height: 10px;
    width: 10px;
    margin-top: 0.4em;
    margin-left: -1.1rem;
    position: absolute;
  }
  &.no-bullet {
    li:before{
      display: none;
    }
  }
}

#frnMain {
  position: relative;
  max-width:470px;
  min-width: 320px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  z-index: 3;
  @include breakpoint(medium up) {
    max-width:map-get($breakpoints, medium);
    margin-left: 0;
    left: 0;
    margin-top: -29px;
  }
  @include breakpoint(medium only) {
    //overflow-x: hidden;
    //-webkit-overflow-scrolling: touch;
    margin-top: -29px;
  }
  @include breakpoint(large up) {
    max-width:map-get($breakpoints, xlarge);
    margin-left: 0;
    left: 0;
    margin-right: auto;
    margin-top: 0;
  }
  @media print, screen and (min-width: (map-get($breakpoints, xlarge) + ($grid-container-center-shifting*2) + 20)){
    margin-left: auto;
    left: -$grid-container-center-shifting;
  }
}

.grid-container {
  width: 100%;
  margin-right: auto;
  background-color: #fff;
  margin-bottom: 50px;
}

.grid-container.last {
  margin-bottom: 0;
}
/* setting channel color */
.nachrichten {
  .frn-container>header h2:before, li:before {
    background-color: $frncolor-news;
  }
  .frnCont_listenGalerie {
    border-color: $frncolor-news;
  }
}
.sport {
    .frn-container>header h2:before, li:before {
    background-color: $frncolor-sport;
  }
  .frnCont_listenGalerie {
    border-color: $frncolor-sport;
  }
}
.finanzen {
  .frn-container > header h2:before, li:before {
    background-color: $frncolor-finanzen;
  }
  .frnCont_listenGalerie {
    border-color: $frncolor-finanzen;
  }
}
.auto {
  .frn-container > header h2:before, li:before {
    background-color: $frncolor-auto;
  }
  .frnCont_listenGalerie {
    border-color: $frncolor-auto;
  }
}
.unterhaltung {
  .frn-container > header h2:before, li:before {
    background-color: $frncolor-unterhaltung;
  }
  .frnCont_listenGalerie {
    border-color: $frncolor-unterhaltung;
  }
}
.lifestyle {
  .frn-container > header h2:before, li:before {
    background-color: $frncolor-lifestyle;
  }
  .frnCont_listenGalerie {
    border-color: $frncolor-lifestyle;
  }
}
.digital {
  .frn-container > header h2:before, li:before {
    background-color: $frncolor-digital;
  }
  .frnCont_listenGalerie {
    border-color: $frncolor-digital;
  }
}
.digitalewelt {
  .frn-container > header h2:before, li:before {
    background-color: $frncolor-digital;
  }
  .frnCont_listenGalerie {
    border-color: $frncolor-digital;
  }
}
.spielen {
  .frn-container > header h2:before, li:before {
    background-color: $frncolor-spielen;
  }
  .frnCont_listenGalerie {
    border-color: $frncolor-spielen;
  }
}

#frnEmailPopup {
  @include breakpoint(small only) {
    margin-top: -20px;
    padding-bottom: 0;
  }
}

  .frn-container {
    padding-top: 20px;
    padding-bottom: 30px;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.2);
    contain: content;
    > header {
      display: flex;
      justify-content: space-between;
      align-items:baseline;
      margin-top: 2px;
      margin-bottom: 13px;
      @include breakpoint(medium up) {
        margin-top: 10px;
        margin-bottom: 19px;
      }
      & h2 {
        font-family: "Open Sans Extrabold", Arial, Helvetica, sans-serif;
        text-transform: uppercase;
        margin-bottom:0;
        margin-right:14px;
        font-weight:800;
        &:before {
          content:"";
          width:16px;
          height:16px;
          display:inline-block;
          margin-right: 7px;
          background-color: $frncolor-startgreen;
          @include breakpoint(large up) {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .h-xtra {
      font-family: "Open Sans Extrabold", Arial, Helvetica, sans-serif;
      text-transform: uppercase;
      margin-top:12px;
    }
    article {
      position: relative;
      p {
        font-size: rem-calc(14);
      }
      h6, .h6 {
        color: $frncolor-secondary;
        text-transform: uppercase;
        display: block;
        line-height: 1.5;
        margin-bottom: 0.1rem;
        margin-top: 0.3rem;
        @include breakpoint(medium up) {
          //display: initial;
        }
      }
    }
    .large-background {
      @include breakpoint(large up) {
        section {
          z-index: 1;
        }
        figure {
          z-index: 2;
        }
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: map-get($grid-margin-gutters, medium)/2;
          bottom: map-get($grid-margin-gutters, medium)/2;
          right: map-get($grid-margin-gutters, medium)/2;
          left: map-get($grid-margin-gutters, medium)/2;
          background-color: #e7ebf2;
        }
      }
    }
    &.first .background:before {
      content: "";
      position: absolute;
      top: map-get($grid-margin-gutters, medium)/2;
      right: map-get($grid-margin-gutters, medium)/2;
      bottom: map-get($grid-margin-gutters, medium)/2;
      left: map-get($grid-margin-gutters, medium)/2;
      background-color: #e7ebf2;
    }
    .background {
      section.cell {
        max-height: 400px;
        padding-left: 15px;
        padding-right: 15px;
        @include breakpoint(large up) {
          max-height: 172px;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
      section {
        z-index: 1;
      }
      figure {
        z-index: 2;
      }
      position: relative;

      &:before{
        content: "";
        position: absolute;
        top: map-get($grid-margin-gutters, medium)/2;
        right: map-get($grid-margin-gutters, medium)/4;
        bottom: map-get($grid-margin-gutters, medium)/2;
        left: map-get($grid-margin-gutters, medium)/4;
        background-color: #e7ebf2;
        @include breakpoint(medium up) {
          right: map-get($grid-margin-gutters, medium)/2;
          left: map-get($grid-margin-gutters, medium)/2;
        }
      }
    }
    &--news > header h2:before{
      background-color:$frncolor-news;
    }
    &--unterhaltung > header h2:before{
      background-color:$frncolor-unterhaltung;
    }
    &--finanzen > header h2:before{
      background-color:$frncolor-finanzen;
    }
    &--sport > header h2:before{
      background-color:$frncolor-sport;
    }
    &--lifestyle > header h2:before{
      background-color:$frncolor-lifestyle;
    }
    &--auto > header h2:before{
      background-color:$frncolor-auto;
    }
    &--spielen > header h2:before{
      background-color:$frncolor-spielen;
    }
    &--digital > header h2:before{
      background-color:$frncolor-digital;
    }
    &--highlights > header h2:before{
      background-color:$frncolor-secondary;
    }
    &--hero > header h2:before{
      display: none;
    }
    &--highlights {
      header nav a{
        color: #84bc34;
      }
    }
    &--topic-c {
      min-height: 1386px;
      @include breakpoint(medium) {
        min-height: 1750px;
      }
      @include breakpoint(large up) {
        min-height: 1130px;
      }
    }
    &--aufmacher-home {
      min-height: 1458px;
      @include breakpoint(large up) {
        min-height: 940px;
      }
    }
    & nav {
      display: none;
      @include breakpoint(medium up) {
        display: flex;
        height: 16px;
        font-size: rem-calc(14);
        flex: 1 1 auto;
      }
      &:before {
        content: "";
        flex: 1 1 auto;
        border-bottom: dotted 1px #979797;
      }
      a {
        display: none;
        @include breakpoint(large up) {
          display: block;
          margin: 0 10px;
          height: 17px;
          flex: 0 0 auto;
        }
      }
    }
    &--hero {
      color: white;
      background: $primary-color;
      a  {
        color: rgb(255, 255, 255);
      }
    }
  }
// Teaser
// -----------
.m-label {
  position: relative;
  &:before, &-left:before {
    content: "Anzeige";
    bottom: 10px;
    color: #fff;
    z-index: 1000000;
    position: absolute;
    text-transform: uppercase;
    background: rgba(0,0,0,.6);
    padding: 1px 4px;
    font-size: .625rem;
    line-height: 1.2;
  }
  &-left:before {
    top: 28px;
    bottom: auto;
    left: -7.7px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}
.icon-pictureshow, .icon-video, .icon-quiz, .icon-goodnews {
  position: relative;
  .teaser-icon {
    z-index: 10;
    width: 56px;
    height: 56px;
    position: absolute;
    bottom: -6px;
    right: 20px;
    background-size: 100%;
  }
}

.icon-goodnews, .icon-pictureshow {
  &:hover::after {
    background: #e5e5e5;
    background: rgb(227 229 231 / 90%);
    border: solid 1px #dcdbdb;
    border-radius: 0.2em;
    bottom: 57px;
    color: #0d0e0e;
    display: block;
    right: 1.6em;
    padding: 0em 0.4em .04em;
    line-height: 1.5;
    position: absolute;
    white-space: nowrap;
    z-index: 98;
    font-size: 0.7rem;
    box-shadow: 2px 2px 5px 0px rgb(2 2 2 / 19%);
    pointer-events: none;
  }
}
.icon-goodnews {
  &:hover::after {
    content: "Die gute Nachricht";
  }
  .teaser-icon {
    background-image: url(../img/icon-good-news.svg);
  }
}
.icon-pictureshow {
  &:hover::after {
    content: "Bildershow";
  }
  .teaser-icon {
    background-image: url(../img/icon-pictureshow.png);
  }
}
.frn-container--gute .icon-pictureshow {
  .teaser-icon {
    background-image: url(../img/icon-good-news.svg);
  }
}
.icon-video {
  .teaser-icon {
    background-image: url(../img/icon-video.png);
  }
}
.icon-quiz {
  .teaser-icon {
    background-image: url(../img/icon-quiz.png);
  }
}

.large-y-collapse {
  @include breakpoint(large up) {
    height: 0;
  }
}

.medium-y-collapse {
  @include breakpoint(medium only) {
    height: 0;
  }
}

.large {
  @include breakpoint(large up) {
    &-h5 {
      font-size: rem-calc(map-deep-get($header-styles, 'large', 'h5', 'font-size'));
    }
    &-h3 {
      font-size: rem-calc(map-deep-get($header-styles, 'large', 'h3', 'font-size'));
    }
  }

}

.top-index {
  z-index: 10;
}

.top-gutter-overlapping {
  margin-top: -(map-get($grid-padding-gutters, medium));
}

.teaser-padding {
  margin: -5px;
  @include breakpoint(medium up) {
    margin: -10px;
  }
}

.teaser-padding figure {
  padding: 5px;
  position: relative;
  @include breakpoint(medium up) {
    padding: 10px;
  }
}

.background {
  .teaser-padding {
    section {
      padding: 6px 20px 20px;
    }
  }
}

.teaser-padding {
  section {
    padding: 5px;
    position: relative;
    @include breakpoint(medium up) {
      padding: 0 20px 20px;
    }
  }
}

.large-teaser-padding-x-xtra section {
  @include breakpoint(large up) {
    padding: 10px;
  }
}

section.image-bg {
  background-color: #e7ebf2;
  padding: 0.7rem;
}

// Border
// ------

.large-text-border-x {
  @include breakpoint(large up) {
    display: flex;
    article {
      align-content: flex-start;
      overflow: hidden;
    }
    section {
      &:before {
        content: "";
        height: 100%;
        width: 1px;
        border-left: dotted 1px #979797;
        position: absolute;
        right: 0;
        margin-top: 7px;
      }
    }
  }
}

.border-bottom {
  @include border-bottom;

  &-hide-for-large {
    @include breakpoint(large up) {
      &:before {
        display: none;
      }
    }
  }
}

.medium-border-bottom {
  @include breakpoint(medium up) {
    @include border-bottom;
  }
}

.grid-padding-y > .cell {
  &.medium-border-top, &.border-top {
    padding-top: 30px;
  }
}

.cell.align-bottom {
  display: flex;
}

.border-top {
  @include border-top;

  &-hide-for-large {
    @include breakpoint(large up) {
      &:before {
        display: none;
      }
    }
  }
}

.medium-border-top {
  @include breakpoint(medium up) {
    @include border-top;
  }
}

.medium-only-border-top {
  @include breakpoint(medium only) {
    @include border-top;
  }
}

.carousel {
  &:hover {
    .slick-prev:before, .slick-next:before {
      @include breakpoint(large up) {
        display: block;
      }
    }
  }
 /* figure {
    min-height: 250px;
    @include breakpoint(large up) {
      min-height: 433px;
    }
  } */
  article{
    &:nth-child(n+2) {
      display:none;
    }
    &:last-child {
      margin-right: -21px;
    }
  }
  &.slick-slider {
    article:nth-child(n+2) {
        display:block;
    }
  }
}
.large-6 .carousel {
  figure {
    min-height: 250px;
    @include breakpoint(large up) {
      min-height: 433px;
    }
  }
}
.slick-prev, .slick-next {
  overflow: hidden;
    z-index: 1;
   // height: 100%;
  height: auto;
    width: 36px;
    top: calc(50% - 75px);
}
.slick-prev {
  left: 1px;
  text-align: left;

  &:before {
    display: none;
    content: "";
    background-image: url(../img/icon-next.png);
    margin: 0 0 0 auto;
    transform: rotate(180deg);
  }
}
.slick-next {
  right: 1px;
  text-align: right;

  &:before {
    display: none;
    content: "";
    background-image: url(../img/icon-next.png);
  }
}
.carousel-full {
  margin-left: rem-calc(-10);
  margin-right: rem-calc(-10);
  .slick-next {
    top: 100px;
    right: 10px;
  }
  .slick-prev {
    top: 100px;
    left: 10px;
  }
  .teaser-padding {
    padding-left: rem-calc(10);
    padding-right: rem-calc(10);
  }
}
.slick-prev:before, .slick-next:before {
    color: #333;
    width: 36px;
    height: 51px;
    background-color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, .88);
}

.slick-dotted {
  &.slick-slider {
    margin-bottom: 0;
  }
}

.slick-dots {
  overflow: hidden;

  li {
    //display:none;
    font-size: 0;
    width: 6px;
    white-space: nowrap;

    &:before {
      border-radius: 4px;
      width: 8px;
      height: 8px;
      background: $frncolor-primary;
    }

    &.slick-active {
      &:before {
        background: $frncolor-secondary;
      }
    }
  }

  @include breakpoint(large up) {
    bottom: auto;
    top: 0;
    text-align: left;
    display: flex;
    z-index: 2;
    li {
      display: block;
      height: 25px;
      text-transform: uppercase;
      width: auto;
      background: #f1f1f1;
      margin: 0;
      font-size: rem-calc(12);
      font-weight: 700;
      padding: 2px 7px;

      &:not(:last-child):before {
        content: "";
        position: absolute;
        height: 12px;
        width: 2px;
        margin: auto;
        top: 1px;
        bottom: 0;
        right: 0;
        background: #21314d;
      }

      &:last-child:before {
        display: none;
      }
    }
    .slick-active {
      background: #21314d;
      height: 30px;
      color: #fff;
      padding-top: 5px;

      &:not(:first-child) {
        margin-left: -2px;
        padding-left: 8px;
        padding-right: 8px;
      }
    }
    a {
      color: #21314d;
    }
  }
}

/* async sqwop container */
div[class*="Async"] {
  height: 250px;
}

.clearBoth {
  clear: both;
}

.frn_cont {
  position: relative;
}

.meistgelesene_artikel {
  ul {
    counter-reset: list-counter;
    margin-left: 0;
  }

  li {
    position: relative;
    background: #289c45;
    margin-bottom: 3px;
    padding-left: 18px;
    padding-right: 8px;

    a {
      color: #fff;
      min-height: 50px;
      display: flex;
      align-items: center;
      padding: $paragraph-margin-bottom/2 0 $paragraph-margin-bottom/2 17px;
    }

    &:nth-child(2n) {
      background: #39b358;
    }

    &:before {
      position: absolute;
      color: #fff;
      content: counter(list-counter);
      counter-increment: list-counter;
      font-size: 1.5rem;
      line-height: 0.79;
      background: transparent;
      height: 20px;
      width: 34px;
      text-align: center;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}
.nachrichten .meistgelesene_artikel {
  li {
    background: #1175c3;
    &:nth-child(2n) {
      background: #2b8ad5;
    }
  }
}
.sport .meistgelesene_artikel {
  li {
    background: #1a9a45;
    &:nth-child(2n) {
      background: #2cb158;
    }
  }
}
.finanzen .meistgelesene_artikel {
  li {
    background: #5bc3c6;
    &:nth-child(2n) {
      background: #70d3d6;
    }
  }
}
.auto .meistgelesene_artikel {
  li {
    background: #a69b89;
    &:nth-child(2n) {
      background: #bbb1a1;
    }
  }
}
.unterhaltung .meistgelesene_artikel {
  li {
    background: #e33ab9;
    &:nth-child(2n) {
      background: #f254cb;
    }
  }
}
.lifestyle .meistgelesene_artikel {
  li {
    background: #d4121a;
    &:nth-child(2n) {
      background: #e94b51;
    }
  }
}
.digitalewelt .meistgelesene_artikel {
  li {
    background: #f98110;
    &:nth-child(2n) {
      background: #f89940;
    }
  }
}
// article section
.frn_articleList ul li::before {
  background: rgb(33, 49, 77);
}

// Listen-Galerie Zitate
// ---------------------
.frnCont_listenGalerie {
  width: 100%;
  margin: 26px 0 70px 0%;
  border: solid 3px #dbdbdb;
  overflow: visible;

  li {
    background-color: transparent !important;
  }

  .frn_cycleButton {
    bottom: auto;
    top: -42px;
    right: 3%;
    background: red;
    height: 0px;
    width: 17px;
    margin: 0 auto;
    left: 0;
    display: none;
    position: absolute;

    &.middle {
      @include breakpoint(medium up) {
        right: 27px;
        left: 17px;
        top: calc(50% - 15px);
        width: auto;
        display: block;
      }
    }

    &.bottom {
      top: auto;
      bottom: -15px;
      display: block;
    }
  }

  .underline {
    text-decoration: underline;
  }

  &.quoteGalerie {
    li {
      padding: 0 48px 40px 50px;

      &:before, &:after {
        width: 50px;
        text-indent: 0;
        text-align: center;
        font-size: 5em;
      }

      &:after {
        line-height: 1em;
      }
    }
  }

  &.socialMediaGalerie {
    border-width: 0;
    @include breakpoint(medium up) {
      //border: solid 3px #84bc34;
      border-width: 3px;
    }

    li {
      width: 100% !important;
      padding: 0;
    }

    .frn_cycleButton {
      display: block;

      &.middle {
        @include breakpoint(medium up) {
          right: 27px;
          left: 17px;
          top: 400px;
          width: auto;
        }
      }
      &.bottom {
        top: auto;
        bottom: -15px;
        display: block;
      }
    }
    .frn_contInlett > strong {
      padding: 0px 28px 0px;
      margin-bottom: -12px;
      display: block;
      @include breakpoint(medium up) {
        padding: 14px 28px 0px;
        margin-bottom: 0;
        font-size: 1.4rem;
  }
}
}

.center {
display: block;
}

.frn_bsNavi {
.left, .right {
  padding-top: 0;
  height: 30px;
  width: 19px;
  position: absolute;
}

.left:after, .right:after {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #fff;
  bottom: 0;
  content: "";
  position: absolute;
  z-index: 2;
}

.left {
  left: -49px;

  &:before {
    display: none;
  }

  &:after {
    background: url(../img/icon-next.png) no-repeat -11px;
    transform: rotate(180deg);
  }
}

.right {
  right: -59px;

  &:before {
    display: none;
  }

  &:after {
    background: url(../img/icon-next.png) no-repeat -11px;
  }
}
}
}

.article_content ol {
list-style: none;
counter-reset: list-counter;
li {
counter-increment: list-counter;
&:before {
  content: counters(list-counter, '.');
  width: 22px;
  height: 22px;
  top: 2px;
  text-indent: 0;
  color: #fff;
  font-size: 14px;
  text-align: center;
  margin-left: -30px;
  position: absolute;
  display: block !important;
}
}
&.decrement {
  li{
    counter-increment: list-counter -1;
  }
}
}
.galerieCounterDecrement ol {
li {
counter-increment: list-counter -1;
}
}

.frnCont_listenGalerie ul, .frnCont_listenGalerie ol {
height: auto;
margin-top: 20px;
width: auto !important;
}

.frnCont_listenGalerie li {
padding: 0 4% 16px 3.4%;
width: auto !important;
}

.frnCont_listenGalerie li:first-child {
display: block;
}

//.frnCont_listenGalerie ol {width:auto !important;margin-top:20px;}
.frnCont_listenGalerie ol li {
display: block !important;
}

.frnCont_listenGalerie.quoteGalerie li {
padding: 0 81px 40px 79px;
width: 100% !important;
box-sizing: border-box;
-moz-box-sizing: border-box;
margin-left: 0;
min-height: 77px;
font-weight: bold;
}

.frnCont_listenGalerie.quoteGalerie li:before {
content: "\201E";
width: 80px;
height: 40px;
position: absolute;
font-family: arial;
font-size: 8rem;
color: #84bc34;
background: transparent;
line-height: 0;
top: -20px;
left: 0;
margin-left: 0;
text-indent: 15px;
text-align: left;
}

.frnCont_listenGalerie.quoteGalerie li:after {
content: "\201C";
content: "\201C";
width: 80px;
height: 88px;
position: absolute;
font-family: arial;
bottom: 0;
right: 0;
font-size: 8rem;
color: #84bc34;
line-height: 0.92;
text-indent: 2px;
}

.frnCont_listenGalerie .center {
margin: auto;
height: 30px;
position: absolute;
right: 1.8%;
left: 0;
top: -38px;
font-size: 1rem;
text-align: center;
width: 117px;
display: none;
}

.frnCont_listenGalerie .center.bottom {
top: auto;
bottom: -49px;
display: block;
}

.frnCont_listenGalerie.socialMediaGalerie .center {
display: block;
}

.frnCont_listenGalerie.socialMediaGalerie li {
margin-left: 0px;
position: relative !important;
padding-bottom: 0;
margin-bottom: 0;
padding-right: 4%;
display: block;
height: 0;
overflow: hidden;
visibility: hidden;
}

.frnCont_listenGalerie.socialMediaGalerie ul li:first-child {
height: auto;
visibility: visible;
}

.frnCont_listenGalerie.socialMediaGalerie li:before {
display: none;
}

.instagram-media {
width: calc(100% - 3px) !important;;
min-width: 281px !important;
position: relative !important;
//width: 550px!important;
//flex-grow: 0!important;
}

.instagram-media {
max-width: 691px !important;
min-height: 633px;
display:  none;
}
.reddit-embed-bq, .imgur-embed-pub {
display: none;
}
.imgur-embed-iframe-pub{
margin: 10px auto !important;
display: block;
}

.twitter-tweet {
width: 100% !important;
max-width: 100% !important;
justify-content: center;
iframe {
width: 550px!important;
flex-grow: 0!important;
}
}
.fnConsEmbed {
&--twitter {
max-width: 550px;
margin: auto;
}
&--glomex {
.ConsentLinks {
  display:none;
}
}
}
#opinary-automation-placeholder:empty + .fnConsEmbed--opinary .ConsentLinks {
display: none;
}

// Amazon-Box
// ----------
.slick-slide {
max-width: 1220px;
}

.frn_contAmazon ul {
margin-left: 0;
}

.frn_contAmazon li {
margin: 0;
}

.frn_contAmazon li:before {
display: none;
}

.frn_contAmazon {
margin: 0 0 40px;
position: relative;
clear: both;
}

.frn_contAmazon .slick-prev {
left: -55px;
top: -58px;
margin: auto;
right: 50px;
}

.frn_contAmazon .slick-next {
left: 58px;
top: -58px;
margin: auto;
right: -50px;
}

.suche .frn_contAmazon .slick-prev, .suche .frn_contAmazon .slick-next {
top: -26px;
}

.frn_contAmazon .slick-prev:before, .frn_contAmazon .slick-next:before {
display: block;
background-position: -1px -1px;
background-repeat: no-repeat;
width: 33px;
height: 48px;
}

.amzTeaser {
display: flex;
margin: 0;
justify-content: space-around;
padding: 8px;
flex-flow: row wrap;
position: relative;
}

.amzTeaser img {
margin: 8px;
}

.amzTeaser a {
outline: none;
}

.amzHeader {
font-size: 1.1875rem;
font-weight: 600;
margin: 25px 0 16px 0;
}

.amzSubTitle {
margin-bottom: 13px;
line-height: 1.2;
}

.amzLogo {
background: url(../img/amzlogo.png) no-repeat;
width: 80px;
height: 23px;
margin-bottom: 14px;
}

.amzTitle {
font-size: 1rem;
font-weight: 600;
line-height: 1.3;
margin-bottom: 6px;
}

.amzText {
max-width: 324px;
padding: 8px;
font-size: 0.875rem;
color: #21314D;
}

.amzListPrice span {
text-decoration: line-through;
}

.amzLowestNewPrice {
font-weight: 700;
color: #df002e;
min-height: 21px;
margin: 0 0.6em 0.6em 0;
}

.amzLowestNewPrice-Btn {
display: flex;
justify-content: space-between;
flex-wrap: wrap;
}

.amzBtn {
background: #84BC34;
font-size: 0.875rem;
text-transform: uppercase;
font-family: "Open Sans Condensed", "Arial Narrow", Arial, Helvetica, sans-serif;
width: 122px;
}
.amzBtn a.amzBtnLnk {
color: #FFFFFF;
}
.amzBtn a {
padding: 5px 0;
display: block;
width: 100%;
text-align: center;
}

.amzCounter {
font-size: 1rem;
position: relative;
right: 0;
left: 0;
margin: auto auto 15px auto;
width: 78px;
text-align: center;
}

.frn_contAmazon .slick-list.draggable {
border: solid 1px #bbb;
}

.frn_contAmazon ul li.slick-slide {
margin-top: 16px;
}


//channel background colors
.auto .channel-color {
background-color: $frncolor-auto;
}

.finanzen .channel-color {
background-color: $frncolor-finanzen;
}

.sport .channel-color {
background-color: $frncolor-sport;
}

.nachrichten .channel-color {
background-color: $frncolor-news;
}

.unterhaltung .channel-color {
background-color: $frncolor-unterhaltung;
}

.lifestyle .channel-color {
background-color: $frncolor-lifestyle;
}

.spielen .channel-color {
background-color: $frncolor-spielen;
}

.digitalewelt .channel-color {
background-color: $frncolor-digital;
}

// wetter
// ------
.frn_contWetter {
float: left;
background-color: #03b9fc;
color: #fff;
padding: 0.5em 0 1.1em 0.9em;
box-sizing: border-box;
-moz-box-sizing: border-box;
margin-bottom: 22px;
width: 100%;
position: relative;
}

#frn_aendernboxsave, #frn_aendernboxcancel, .frn_contWetter h2, .frn_contHoroskop h2, .frn_contWetterFull h2, .frn_wText {
font-size: 1.6em;
text-transform: uppercase;
font-family: 'Open Sans Condensed', Arial, Helvetica, sans-serif;
}

.frn_contWetterFull {
font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

.wetter1 .frn_wetterOrt {
font-weight: normal;
}

#frn_wetterboxhead, #frn_wetterboxcomplete {
width: auto;
}

#frn_wetterboxhead {
font-size: 1.2em;
}

#frn_wetterbox {
border: none;
}

#frn_wetterbox a {
border-color: #fff;
padding: 6px 2%;
}

#frn_wetterbox a.wetter3 {
border: none;
}

#frn_wetterBoxHeadAendernLink, #frn_wetterboxcomplete .frn_flyMore a:link, .frn_wetterRegen, .frn_wetterOrt, .frn_wetterGrad {
color: #fff;
}

#frn_wetterboxcomplete #frn_wetterBoxHeadAendernLink {
position: absolute;
top: 10px;
right: -6px;
height: 20px;
width: 20px;
overflow: hidden;
padding-left: 26px;
background-position: 0px -154px;
box-sizing: border-box;
-moz-box-sizing: border-box;
}

.frn_wetterOrt {
font-size: 1.2em;
text-transform: uppercase;
white-space: nowrap;
}

#frn_wetterboxcomplete img {
left: 5%;
top: 22px;
}

.frn_wKInfo {
left: 45%;
top: 27px;
white-space: nowrap;
}

.frn_wetterRegenPic, .frn_wetterRegen {
display: none;
}

.frn_contWetterFull h2 {
margin: 30px 0 20px;
}

#frnWetterKarte {
text-align: center;
}

#frnWetterKarteImg {
width: 90%;
}

.frn_wText {
margin: 17px 0 10px 0;
}

.frn_wTextSub {
font-size: 1.3em;
}

#frn_wetterboxcomplete .frn_flyMore, #frn_wetterboxcomplete .frn_flyMore a {
background-image: none;
margin-right: 2.4%;
display: none;
}

#frn_wetterboxcomplete a {
background-image: none;
width: 29%;
font-family: 'Open Sans Condensed', Arial, Helvetica, sans-serif;
}

#frn_wetterboxcomplete .frn_waiting {
background: url("../img/loading2.gif") no-repeat 50% 50%;
padding: 28px;
background-size: 54px;
}

#frn_aendernboxbuttons {
width: 100%;
}

#frn_aendernboxsave {
margin-right: 10px;
}

#frn_aendernboxcancel {
margin-left: 0;
}

#frn_aendernboxsave, #frn_aendernboxcancel {
background-color: #000;
padding: 6px 8px 6px 16px;
position: relative;
cursor: pointer;
font-size: 1.3em;
font-weight: bold;
overflow: hidden;
}

#frn_aendernboxsave:after, #frn_aendernboxcancel:after {
background-color: #000;
content: "";
height: 9px;
width: 9px;
position: absolute;
z-index: 1;
left: -2px;
top: 12px;
transform: rotate(45deg);
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
}

#frn_aendernboxsave:before, #frn_aendernboxcancel:before {
background-color: #fff;
content: "";
height: 9px;
width: 9px;
position: absolute;
z-index: 1;
left: 2px;
top: 12px;
transform: rotate(45deg);
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
}

.frn_weather_page #frnBreadcrumb .last h1 {
background: url("../img/wetterhead.png") #03b9fc no-repeat right -8px;
color: #fff;
padding: 20px;
display: block;
float: none;
}

.frn_weather_page #frnTopicHeadline span {
text-transform: none;
}

.frn_weather_page #frnBreadcrumb {
float: none;
height: auto;
}

.frn_weather_page #frnBreadcrumb li {
float: none;
}

.frn_weather_page #frnBreadcrumb li.last {
display: block;
}

.frn_contReisewetter div.frn_onlyList ul {
width: 47%;
}

.weather_tabs, .weather_current_infos, .weather_periods {
clear: both;
}

.weather_tabs li {
float: left;
background: #F7F7F7;
width: 16.66%;
height: 143px;
text-align: center;
padding: 4px;
box-sizing: border-box;
-moz-box-sizing: border-box;
font-size: 1rem;
border-right: solid 1px #fff;
cursor: pointer;
}

.weather_tabs li:last-Child {
border: none;
}

.weather_tabs {
//border-bottom: solid 3px #1175C3;
height: 120px;
margin-bottom: 3px;
}

.weather_tabs h3 {
font-weight: normal;
}

.weather_tabs img {
height: 60px;
}

.weather_tabs.active1 li:nth-child(1), .weather_tabs.active2 li:nth-child(2), .weather_tabs.active3 li:nth-child(3), .weather_tabs.active4 li:nth-child(4), .weather_tabs.active5 li:nth-child(5), .weather_tabs.active6 li:nth-child(6) {
background: #1175C3;
color: #fff;
}

.weather_current_infos {
height: 138px;
}

.weather_current_infos li {
float: left;
color: #fff;
box-sizing: border-box;
-moz-box-sizing: border-box;
height: 190px;
width: 50%;
background: #1175C3;
@include breakpoint(medium up) {
height: 170px;
}
}

.weather_current_infos li:last-child {
font-size: 1rem;
padding-top: 25px;
padding-left: 5%;
line-height: 1.5;
@include breakpoint(medium up) {
line-height: 2.0;
}
}

#weatherIconBig {
width: 31%;
float: left;
text-align: center;
position: relative;
}

#weatherIconBig img {
width: 100%;
-webkit-filter: brightness(6);
filter: brightness(6);
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin: auto;
}

// hover effect weather raus
.frnWetterContent a {
  color: #5a6374;
  display: block;
  height: 100%;
}
.frnWetterContent a:hover {
text-decoration: none;
}

#weatherMaxTemprature {
font-size: 50px;
color: #FFFFFF;
text-align: center;
font-weight: 700;
margin-top: 57px;
width: 100%;
}

.weather_periods {
margin-top: 20px;
float: right;
width: 76%;
max-width: 510px;
margin-bottom: 30px;
  @include breakpoint(medium up) {
    width: 100%;
  }
  @include breakpoint(large up) {
    max-width: 1052px;
  }
}

.weather_periods li {
float: left;
box-sizing: border-box;
-moz-box-sizing: border-box;
width: 25%;
font-size: 1rem;
text-align: center;
border-right: solid 1px #fff;
}

.weather_periods li div:nth-child(2n-1) {
background: #f7f7f7;
}

.weather_periode_wind {
background: #f7f7f7;
margin-top: 14px;
padding: 46px 0 6px 0;
position: relative;
}

.weather_icon_periode {
height: 90px;
background: red;
text-align: center;
position: relative;
}

.weather_icon_periode svg {
max-width: 70px;
margin-top: 20px;
margin: auto;
display: block;
}

.weather_icon_periode:before {
padding-top: 5px;
display: block;
font-size: 14px;
@include breakpoint(medium up) {
font-size: 16px;
}
}

.weather_periode_temprature, .weather_periode_precipitation {
padding: 10px 0;
position: relative;
}

#weatherIconPeriode1:before {
content: 'Nachts';
}

#weatherIconPeriode1:after, #weatherTempraturePeriode1:after, #weatherPrecipitationPeriode1:after, #weatherWindPeriode1:after {
position: absolute;
left: -141%;
right: 100%;
bottom: 0;
top: 0;
margin: auto;
height: 23px;
text-align: right;
padding-right: 10px;
font-size: 14px;
@include breakpoint(medium up) {
left: -100%;
font-size: 16px;
}
}

#weatherIconPeriode1:after {
content: 'Vorhersage';
}

#weatherTempraturePeriode1:after {
content: 'Temperatur';
}

#weatherPrecipitationPeriode1:after {
content: 'Niederschlag';
}

#weatherWindPeriode1:after {
content: 'Wind';
}

#weatherIconPeriode2:before {
content: 'Morgens';
}

#weatherIconPeriode3:before {
content: 'Mittags';
}

#weatherIconPeriode4:before {
content: 'Abends';
}

.weather_icon {
max-width: 70px;
margin: auto;
}

.weather_icon svg {
max-height: 60px;
}

.weather_periode_wind:before {
background: url("../img/ico-wind.svg") no-repeat center;
position: absolute;
width: 24px;
height: 24px;
content: "";
top: 13px;
right: 0;
left: 0;
margin: auto;
}

.wind_sw:before {
transform: rotate(45deg);
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
}

.wind_w:before {
transform: rotate(90deg);
-webkit-transform: rotate(90deg);
-ms-transform: rotate(90deg);
}

.wind_nw:before {
transform: rotate(135deg);
-webkit-transform: rotate(135deg);
-ms-transform: rotate(135deg);
}

.wind_n:before {
transform: rotate(180deg);
-webkit-transform: rotate(180deg);
-ms-transform: rotate(180deg);
}

.wind_no:before {
transform: rotate(225deg);
-webkit-transform: rotate(225deg);
-ms-transform: rotate(225deg);
}

.windo:before {
transform: rotate(270deg);
-webkit-transform: rotate(270deg);
-ms-transform: rotate(270deg);
}

.wind_so:before {
transform: rotate(315deg);
-webkit-transform: rotate(315deg);
-ms-transform: rotate(315deg);
}

.cls-1, .cls-2 {
fill: none;
stroke: #5a5a5a !important;
stroke-miterlimit: 10;
}

.weather_tabs.active1 li:nth-child(1) .cls-1, .weather_tabs.active1 li:nth-child(1) .cls-2, .weather_tabs.active2 li:nth-child(2) .cls-1, .weather_tabs.active2 li:nth-child(2) .cls-2, .weather_tabs.active3 li:nth-child(3) .cls-1, .weather_tabs.active3 li:nth-child(3) .cls-2, .weather_tabs.active4 li:nth-child(4) .cls-1, .weather_tabs.active4 li:nth-child(4) .cls-2, .weather_tabs.active5 li:nth-child(5) .cls-1, .weather_tabs.active5 li:nth-child(5) .cls-2, .weather_tabs.active6 li:nth-child(6) .cls-1, .weather_tabs.active6 li:nth-child(6) .cls-2, .weather_icon_big .cls-1, .weather_icon_big .cls-2, .frnStickyNavi .frn_weather_icon_header_outer .cls-1, .frnStickyNavi .frn_weather_icon_header_outer .cls-2 {
stroke: #ffffff !important;
}

.weather_icon_big .cls-1, .weather_icon_big .cls-2 {
stroke-width: 1px !important;
}

#weatherMaxTemprature:before {
content: "Max";
position: absolute;
font-size: 1rem;
margin-top: -11px;
}

#weatherSunshine {
line-height: 1.3;
margin-top: 6px;
text-overflow: ellipsis;
overflow: hidden;
width: 100%;
white-space: nowrap;
}

.frn_weather_search_top #frn09_query_top {
background-color: #EEEEEE;
width: 446px;
padding-left: 10px;
font-size: 1.58em;
height: 100%;
line-height: 30px;
box-sizing: border-box;
-moz-box-sizing: border-box;
max-width: 100%;
}

.frn_weather_search_top {
margin-bottom: 28px;
height: 35px;
margin-top: 25px;
position: relative;
}

#frnKopfLeiste .frn_text {
text-transform: none;
}

/* SUCHKASTEN START */
.frn_weather_search_top {
  z-index: 1;
  position: relative;
}

.frn_wetterSuche #frn_search_results, .frn_weather_search_top #frn_search_results_top {
  width: 226px;
  right: 0px;
  border: solid 1px #DEDEDE;
  display: none;
  position: absolute;
  z-index: 30;
  margin-top: -21px;
  background-color: #ffffff;
}

.frn_wetterSuche #frn_search_results, #frn_search_results li, .frn_weather_search_top #frn_search_results_top ul, #frn_search_results_top li {
  padding: 0;
  margin: 0;
  border: 0;
  list-style: none;
}

.frn_wetterSuche #frn_search_results, .frn_weather_search_top #frn_search_results_top {
  border-top: solid 1px #DEDEDE;
}

.frn_wetterSuche #frn_search_results a, .frn_weather_search_top #frn_search_results_top a {
  display: block;
  text-decoration: none;
  color: #000000;
  padding: 8px
}

.frn_wetterSuche #frn_search_results a span, .frn_weather_search_top #frn_search_results_top a span {
  font-weight: bold;
}

.frn_wetterSuche #frn_search_results a small, .frn_weather_search_top #frn_search_results_top a small {
  display: block;
  text-decoration: none;
  color: #999999;
  font-weight: normal;
}

.frn_wetterSuche #frn_search_results a:hover, .frn_weather_search_top #frn_search_results_top a:hover {
  background: #64D6FF;
}

#frn_search_results ul, #frn_search_results_top ul {
  padding: 6px;
}

.frn_weather_search_top #frn_search_results_top {
  top: 32px;
  right: 0;
  left: 0;
  width: auto;
  margin: 0;
  max-width: 443px;
  max-height: 500px;
  overflow: auto;
  padding: 5px;
}

.frn_weather_search_top #frn_search_results_top a {
  font-size: 1.2em;
  font-weight: normal;
}

.frn_weather_search_top #frn_search_results_top a span {
  font-weight: bold;
  font-size: 1em;
}


.frn_tabModuleContent .frn_weather_search_top #frn_search_results_top {
  font-size: 0.7em;
  z-index: 1;
}

.frn_24h_foundCity {
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  padding: 3px 5px;
  cursor: pointer;
}

/* SUCHKASTEN END */

// Sport
// -----
.frn_sportTabHead, .frn_sportLiveTab, .frn_rennkalender th {
  text-transform: uppercase;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

.channel_sport .frn_sportLiveTab .frn_sportLiveTabScore a {
  color: #1a9a45;
  text-decoration: none;
}

.channel_sport .frn_sportLiveTab .frn_sportLiveTabScore a:hover {
  color: #2cb158;
}

.frn_sportTabHead {
  font-size: rem-calc(16);
  background: none;
  height: auto;
  padding: 0px 0;
  background-position: bottom;
  background-repeat: repeat-x;
  border: none;
  a {
    visibility: hidden;
  }
}

.frn_sportTabHead table {
  margin: 0 auto;
  width: 100%;
}

.frn_sportTabCenter {
  width: 100%;
}

.frn_sportTabCenter strong {
  color: #1a9a45;
  font-weight: normal;
}

#frnContentMain .frn_sportLiveTab, #frnContentRight .frn_sportLiveTab {
  width: 96.6%;
  margin-left: -4px;
}

.frn_sportLiveTab td {
  font-size: rem-calc(13);
  font-weight: normal;
  vertical-align: middle;
}

.frn_sportLiveTab .frn_sportLiveTabDate {
  background-color: transparent;
  color: #999;
  font-size: 0.9em;
  font-weight: normal;
  text-transform: none;
  display: block;
  width: 100%
}

.frn_sportLiveTab .frn_sportLiveTabScore {
  font-size: rem-calc(36);
  color: #888;
}

.frn_sportLiveTab .frn_sportLiveTabScore i {
  font-size: rem-calc(36);
  font-style: normal;
}

.frn_sportLiveTab span {
  background-color: transparent;
  color: #333;
  padding: 0;
  white-space: nowrap;
  float: left;
  font-weight: normal;
}

.frn_sportLiveTickerHead span {
  font-weight: bold;
}

.frn_sportTab {
  overflow: hidden;
}

#frnContentMain .frn_cont.frn_sportTab {
  margin-bottom: 27px;
}

.frn_sportLiveTab span.match_active a:link, .frn_sportLiveTab span.match_active a:visited {
  background-color: transparent;
  color: #669900;
  padding: 0;
}

.frn_sportTabLeft, .frn_sportTabRight {
  position: relative;
}

.frn_sportTabLeft div, .frn_sportTabRight div {
  overflow: hidden;
  width: 50px;
  height: 50px;
  position: relative;
}

.frn_sportTabLeft a:before, .frn_sportTabRight a:before {
  content: "\e605";
  position: absolute;
  z-index: 1;
  left: -20px;
  top: -15px;
  font-family: fn-icons;
  color: #333;
  font-size: 5em;
  line-height: 1;
}

.frn_sportTabLeft a:hover:before, .frn_sportTabRight a:hover:before {
  color: #808080;
}

.frn_sportTabLeft a:before {
  content: "\e606";
  left: -25px;
}

.frn_sportTabLeft div img, .frn_sportTabRight div img {
  display: none;
}

.frn_sportTabHead td {
  vertical-align: middle;
}

.frn_sportLiveTab {
  margin: 0;
}

.frn_sportLiveTab tbody tr:nth-child(even) {
  background-color: transparent;
}

.frn_sportLiveTabTrenner {
  display: none;
}

.frn_sportLiveTabRow2, .frn_sportLiveTabRow2 td, .frn_sportLiveTab2, .frn_sportLiveTab2 td {
  background: #f0f0f0;
  padding: 0 10px;
  transition: all 0.5s ease-out 0s;
}

.frn_sportLiveTabRow1, .frn_sportLiveTabRow1 td, .frn_sportLiveTab1, .frn_sportLiveTab1 td {
  background: transparent;
  padding: 0 10px;
  transition: all 0.5s ease-out 0s;
}

.frn_sportLiveTabCol2 {
  white-space: nowrap;
}

/* article ol li list background and css fixes */
.article article.frn-article__left li {
  position: relative;
}
.article .article_content ol li:before {
  background: #289c45;
}
.nachrichten.article .article_content ol li:before {
  background: #1175c3;
}
.sport.article .article_content ol li:before {
  background: #289c45;
}
.finanzen.article .article_content ol li:before {
  background: #5bc3c6;
}
.auto.article .article_content ol li:before {
  background: #a69b89;
}
.unterhaltung.article .article_content ol li:before {
  background: #e33ab9;
}
.lifestyle.article .article_content ol li:before {
  background: #d4121a;
}
.digitalewelt.article .article_content ol li:before {
  background: #f98110;
}

#frnGoToTop {
  bottom:-50px !important;
  top:auto !important;
  transition:all 0.5s ease-out 0.5s;
  transition-property: bottom, top, opacity;
  position:fixed;
  opacity:0;
  width:50px;
  height:50px;
  right:auto;
  left:1332px;
  margin:auto;
  z-index:9;
  background:#84bc34;
  cursor:pointer;
  @media print, screen and (max-width: 1390px){
    left: auto;
    right: 10px;
  }
  @media print, screen and (min-width: (map-get($breakpoints, xlarge) + ($grid-container-center-shifting*2) + 20)){
    left: 1096px;
    right: 0;
  }
  &.is-stuck {
    top:auto !important;bottom:20px !important;opacity:1;
  }
  &:hover {
    background:#76AA2C;
  }
  &:before, &:after {
    content: "";position: absolute;height: 6px;width: 16px;top: 21px;background: #fff;
  }
  &:before {
    transform: rotate(45deg);-webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);-sand-transform: rotate(45deg);left: 22px;
  }
  &:after {
    transform: rotate(-45deg);-webkit-transform: rotate(-45deg);-ms-transform: rotate(-45deg);-sand-transform: rotate(-45deg);left: 13px;
  }
}
#frnGoToTop.visible {
  opacity: 1;
}
/* 404 error css */
.content-error {
  text-align: center;
  font-family: "Open Sans bold",Arial,Helvetica,sans-serif;
}
.error-intro {
  color: #21314d;
  font-size: 2rem;
  font-weight: 900;
  margin: 0;
}
.error-status {
  color: #84bc34;
  font-size: 11rem;
  line-height: 11rem;
  font-weight: 700;
}
.error-content {
  font-size: 18px;
}
.meistgelesene_artikel_error_seite  li {
  background: #1175c3;
}

.meistgelesene_artikel_error_seite li:nth-child(2n) {
  background: #2b8ad5;
}
.meistgelesene_artikel_error_seite h3 {
  border-bottom: 3px solid #2b8ad5;
}
.page_not_foundTeaser article section .teaser-subject {
  display: none;
}

// Horoskope
// ---------
.horoskope-rating-label {
  margin-bottom: 0.5rem;
}
// cartoons

.frn-container--cartoons article figure:hover picture {
  height: 100%;
  display: block;
  overflow: hidden;
}
.frn-container--cartoons article figure.icon-pictureshow:before {
  z-index: 1;
}
//:not(:last-child)
.frn-container--cartoons div:not(:last-child) article figure:hover img {
  scale: 1.5;
  transform: translate(0%,15%);
  transition-duration: 1s;
}

body.category:not(.index){
  #frn-loginbox img {padding-top: 0 !important}
  #frn-loginbox div {min-height:unset !important}
  #frn-loginbox p {margin:3px 0 0 !important; line-height:16px !important;font-size:16px !important; margin: 4px 0 !important}
  #frn-loginbox .message {display:none}
  #frn-loginbox .red {padding: 3px 10px !important }
}

.carousel-full article:nth-child(4n) section:before {display:none;}

#bulls_container {
  @include breakpoint(small only) {
    margin-top: -29px;
  }
}

/* polizei meldungen */
.polizeimeldungen_image {
  padding-top: 15%;
  background: url("../img/frn_polizeimeldungen.jpg") left center no-repeat;
  background-size: 100%;
}
.meldungen_filtern {
  padding: 5px 10px 20px 20px;
  background-color: #22304d;
}
.meldungen_title {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 15px;
}
.meldungen_filtern form select {
  width: 98%
}
/* unternehmen images on agb , datenschutz ...seite */
.unternehmensseiten_image {
  padding-top: 20%;
  background: url("../img/company_Image.jpg") left center no-repeat;
  background-size: 100%;
  position: relative;
  top: -29px;
  margin-left: -28px;
  margin-right: -31px;
}
/* order */
.small-order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}
.small-order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

a[name], h3[id] {
  scroll-margin-top: 140px;
  @include breakpoint(large up) {
    scroll-margin-top: 200px;
  }
}

/* breaking news */
.frn-breaking-news {
  background:#FFEC8F;
  margin-bottom:0px;
  padding:0;
  line-height: 1.3;
  font-weight: 600;
  a {
    display:block;
    height:100%;
    padding: 12px;
    @include breakpoint(large up) {
      padding: 22px 30px;
    }
    &:hover {
      text-decoration: none;
    }
  }
  &-label {
    text-transform: uppercase;
    color:#DF002E;
    margin-right: 10px;
  }
}
.teaser-title {
  hyphens: auto;
}

.frn-container.frn-container--news, .frn-container.frn-container--wirtschaft, .frn-container.frn-container--sport, .frn-container.frn-container--gute, .frn-container.frn-container--polizeimeldungen {
  @include breakpoint(small only) {
    padding-bottom: 0;
  }
}

.frn-item-liveticker1{
  position: relative;
  text-indent: 5px;
  &:before {
    z-index: 2;
    border-radius: 6px;
    left: 1px;
    top: 1px;
    animation: blink 1.2s infinite;
  }
  &:after {
    content: "Live:";
    background: #21314d;
    position: absolute;
    top: 0;
    left: -23px;
    color: #fff;
    padding: 0 5px 0 23px;
    text-indent: 0px;
  }
}
.frn-item-liveticker1.ticker2{
  text-indent: 0;
  a {
    position: relative;
    color: #fff;
    z-index: 3;
    &.hst-fab {
      position: absolute;
    }
  }
  &:after {
    content: "";
    width: calc(100% + 23px);
    height: 100%;
  }
}
.frn-item-liveticker1.ticker3{
  &:before {
    background: #84bc34;
    animation: blink2 1.5s linear infinite;
  }
  &:after {
    background: #21314d;
  }
}
@keyframes blink {
  0% {
    opacity: 1
  }

  59% {
    opacity: 1
  }

  60% {
    opacity: .65
  }

  65% {
    opacity: .65
  }

  66% {
    opacity: .3
  }

  94% {
    opacity: .3
  }

  95% {
    opacity: .65
  }

  100% {
    opacity: .65
  }
}
@keyframes blink1 {
  0%{background-color: #fff;}
  50%{background-color: #e0e0e0;}
  100%{background-color: #fff;}
}
@keyframes blink2 {
  0%{background-color: #84bc34;}
  50%{background-color: #e0e0e0;}
  100%{background-color: #84bc34;}
}